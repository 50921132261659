import { AuthService } from './../../services/auth.service';
import { UiService } from './../../services/ui.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  public registerForm: UntypedFormGroup;
  constructor(private uiService: UiService, private authService: AuthService) { }

  ngOnInit() {
    this.registerForm = new UntypedFormGroup({
      email: new UntypedFormControl('', Validators.compose([
        Validators.required, Validators.email
      ])),
      first_name: new UntypedFormControl('', Validators.compose([
        Validators.required, Validators.minLength(3)
      ])),
      password: new UntypedFormControl('', Validators.compose([
        Validators.required, Validators.minLength(6)
      ])),
      last_name: new UntypedFormControl('', Validators.compose([
        Validators.required, Validators.minLength(3)
      ]))
    })
  }
  public goToLogin() {
    this.uiService.goToRoute('login');
  }

  public registerUser() {
    this.authService.registerUserEmail(this.registerForm.value);
  }
}
