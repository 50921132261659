import { FuncService } from './func.service';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { UiService } from './ui.service';
import { ToastService } from './toast.service';
import * as fireauth from 'firebase/auth';
import { Timestamp } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public createdAt = Timestamp.now().seconds;
  constructor(
    public afauth: AngularFireAuth,
    public firestore: AngularFirestore,
    public router: Router,
    public toastS: ToastService,
    private uiService: UiService,
    private funcService: FuncService
  ) { }
  public loginUserEmail(loginData) {
    this.uiService.showLoader();
    return this.afauth
      .signInWithEmailAndPassword(loginData.email, loginData.password)
      .then((res) => {
        this.uiService.hideLoader();
        this.firestore
          .collection('ejhUsersAcc', (ref) =>
            ref.where('email', '==', loginData.email)
          )
          .get()
          .toPromise()
          .then((resdata) => {
            resdata.docs.map((rdata) => {
              const uData: any = rdata.data();
              delete uData['verifyToken'];
              localStorage.setItem('ejhUser', JSON.stringify(uData));
            });
            setTimeout(() => {
              this.funcService.getUserProfile().then((res) => {
                this.funcService.isEmailVerified(res);
              });
            }, 500);
            localStorage.setItem('userLogged', 'true');
            this.router.navigate(['services']);
            this.uiService.showSuccess('Welcome to EjumaHub!');
            // this.funcService.isUserLocationSet();
          });
      })
      .catch((err) => {
        this.uiService.hideLoader();
        this.toastS.mainError(err.message);
      });
  }
  public logoutUser() {
    this.uiService.showLoader();
    return this.afauth.signOut().then(() => {
      this.uiService.hideLoader();
      localStorage.removeItem('userLogged');
      localStorage.removeItem('ejhUser');
      this.router.navigate(['login']);
      const modalBack: any = document.getElementsByClassName('modal-backdrop');
      for (let index = 0; index < modalBack.length; index++) {
        const element = modalBack[index];
        element.style.display = 'none'
      }
      // window.location.reload();
    });
  }
  get authState(): any {
    return this.afauth.authState;
  }
  public async adminAuthUser() {
    return this.afauth.currentUser;
  }

  // public 

  public async googleSignin() {
    this.uiService.showLoader();
    try {
      const provider = new fireauth.GoogleAuthProvider();
      const credential = await this.afauth.signInWithPopup(provider);
      const name = credential.user.displayName.split(' ');
      const userData = {
        ejuUAKey: credential.user.uid,
        createdAt: this.createdAt,
        email: credential.user.email,
        photo_url: credential.user.photoURL,
        first_name: name[0],
        last_name: name[1],
        location: '',
        verified: true
      };
      return this.firestore
        .collection('ejhUsersAcc', (ref) =>
          ref.where('email', '==', credential.user.email)
        )
        .get()
        .toPromise()
        .then((resdata) => {
          this.uiService.hideLoader();
          localStorage.setItem('userLogged', 'true');
          localStorage.setItem('ejhUser', JSON.stringify(userData));
          this.router.navigate(['services']);
          this.uiService.showSuccess('Welcome to EjumaHub!');
          // setTimeout(() => {

          // }, 2000);
          if (resdata.empty === true) {
            this.firestore
              .collection('ejhUsersAcc')
              .doc(credential.user.uid)
              .set(userData);
            this.funcService.isUserLocationSet();
            this.funcService.updateUserCount();
          } else {
            resdata.docs.map((locD) => {
              if (locD.data()['location'] === '') {
                this.funcService.isUserLocationSet();
              }
              localStorage.setItem('ejhUser', JSON.stringify(locD.data()));
            })
          }
        });
    } catch (error) {
      this.uiService.hideLoader();
      this.uiService.showError(error);
    }
  }


  public sendPasswordReset(email) {
    this.uiService.showLoader();
    return this.afauth
      .sendPasswordResetEmail(email)
      .then(() => {
        this.uiService.hideLoader();
        this.router.navigate(['login']);
        this.uiService.showSuccess('Password reset email sent to your email.');
      })
      .catch((err) => {
        this.uiService.hideLoader();
        this.uiService.showError(err.message);
      });
  }

  public registerUserEmail(data) {
    const randNum = Math.floor(Math.random() * 11);
    this.uiService.showLoader();
    data['createdAt'] = this.createdAt;
    this.afauth
      .createUserWithEmailAndPassword(data.email, data.password)
      .then((resdata) => {
        delete data.password;
        data['ejuUAKey'] = resdata.user.uid;
        data['location'] = '';
        data['verified'] = false;
        data['photo_url'] = `../../../assets/img/backgrounds/bg${randNum}.png`;
        this.firestore
          .collection('ejhUsersAcc')
          .doc(resdata.user.uid)
          .set(data)
          .then(() => {
            this.uiService.hideLoader();
            this.funcService.updateUserCount();
            this.funcService.isEmailVerified(data);
            this.router.navigate(['services']);
            this.uiService.showSuccess(`Welcome to EjumaHub!`);
            localStorage.setItem('userLogged', 'true');
            localStorage.setItem('ejhUser', JSON.stringify(data));
          });
      })
      .catch((err) => {
        this.uiService.hideLoader();
        this.uiService.showError(err.message);
      });
  }

  public requestPasswordReset(email) {
    this.uiService.showLoader();
    this.logoutUser();
    return this.afauth
      .sendPasswordResetEmail(email)
      .then(() => {
        this.uiService.hideLoader();
        this.router.navigate(['login']);
        this.uiService.showSuccess('Password reset email sent to your email!');
      })
      .catch((err) => {
        this.uiService.hideLoader();
        this.uiService.showError(err.message);
      });
  }
}
