import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
  constructor(public rt: Router, public authS: AuthService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const useLogBool = localStorage.getItem('userLogged');
    console.log(useLogBool);

    if (useLogBool === 'false' || useLogBool === null) {
      return true;
    } else if (useLogBool === 'true') {
      console.log('log yes');
      this.rt.navigate(['home']);
      return false;
    }

  }
}