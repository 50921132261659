import { FuncService } from './../../services/func.service';
import { AuthService } from './../../services/auth.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isLoggedIn = null;
  isRegularRoute = true;
  public userData = {
    photo_url: ''
  };
  public cartCount;
  constructor(private router: Router, private authService: AuthService, private funcService: FuncService) {}

  ngOnInit() {
    this.getDatabase();
    this.cartCount = this.funcService.cartCount;
    this.funcService.currentCartCount.subscribe(data => {
      this.cartCount = data;
    });
    this.funcService.changedImg.subscribe(data => {
      this.userData['photo_url'] = data;
    });
  }

  public async getCartCount() {
    const bookCart = await localStorage.getItem('bookingCart');
    const bookCartArr = JSON.parse(bookCart);
    const bookCartCount = bookCartArr.length;
    this.cartCount = bookCartCount;
  }

  public logoutMainUser() {
    this.authService.logoutUser();
  }
  public async getDatabase() {
    this.isLoggedIn = await localStorage.getItem('userLogged');
    const userD = await localStorage.getItem('ejhUser');
    this.userData = JSON.parse(userD);
  }

  public callNumber() {
    const ahref = document.createElement('a');
    ahref.href = `tel:0302984047`;
    ahref.click();
  }
}
