import { UiService } from './../../services/ui.service';
import { FuncService } from './../../services/func.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { format, parseISO } from 'date-fns';
import { HttpHeaders } from '@angular/common/http';
declare var $;

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss'],
})
export class ServiceListComponent implements OnInit {
  public todaysDate;
  public maxDate;
  public activeService = [];
  public searchServiceMain = [];
  public activeTab;
  public seCategory;
  public searchText = '';
  public searchActivate = false;
  public bookdate = '';
  public booktime = '';
  public bookdesc = '';
  public selectedService = '';
  public selectedCategory = '';
  public subData = null;
  public serviceCategories = [];
  public popularCategories = [];
  constructor(private funcService: FuncService, private uiService: UiService, private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.seCategory = '';
    this.uiService.activeCategoryState.subscribe(data => {
      this.subData = data;
      setTimeout(() => {
        if (data !== null) {
          const serDiv = document.getElementById('serc-con');
          serDiv.scrollIntoView({
            behavior: 'smooth',
          });
          this.changeActiveTab(data[0], data[1] - 1);
        }
      }, 500);
    });
    this.getAllServices();
    $('.owl-carousel').owlCarousel({
      stagePadding: 50,
      loop: true,
      margin: 10,
      nav: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 5,
        },
      },
    });
    if (this.subData === null) {
      setTimeout(() => {
        const getActive = document.getElementsByClassName('pill-main');
        getActive[8].classList.remove('active')
        this.activeTab = 0;
      }, 500);
    }
    setTimeout(() => {
      this.assignDates();
      this.getActiveParams();
    }, 500);
  }

  public getActiveParams() {
    this.activeRoute.params.subscribe(data => {
      if (data.id !== undefined) {
        const mService = data.id;
        const findService = this.serviceCategories.find(a => a.title === mService);
        const findIndexService = this.serviceCategories.findIndex(a => a.title === mService);
        this.changeActiveTab(findService, findIndexService);
        this.activeTab = findIndexService;
      }
    })
  }

  public goToCategory() {
    const eCat = this.serviceCategories.find(cat => cat.title === this.seCategory);
    const eCatIndex = this.serviceCategories.findIndex(cat => cat.title === this.seCategory);
    this.activeTab = eCatIndex;
    const elmnt = document.getElementById(eCat.title);
    elmnt.scrollIntoView({
      behavior: 'smooth',
    });
  }

  public openBookingPop(service, category) {
    $('#bookModal').modal({ backdrop: 'static', keyboard: false });
    this.selectedService = service;
    this.selectedCategory = category;
  }

  public changeActiveTab(category, index) {
    if (this.searchActivate === false) {
      this.mainTabChange(category, index);
    } else {
      this.searchActivate = false;
      setTimeout(() => {
        this.mainTabChange(category, index);
      }, 200);
    }
  }
  public mainTabChange(category, index) {
    const elmnt = document.getElementById(category.title);
    elmnt.scrollIntoView({
      behavior: 'smooth',
    });
    this.activeService = category;
    this.activeTab = index;
  }

  public getAllServices() {
    this.funcService.getService().subscribe((data) => {
      this.serviceCategories = data['resources'];
      this.serviceCategories.map(res => {
        res.services.map(mres => {
          this.searchServiceMain.push({ img: mres.img, category: res.title, service: mres.title });
        });
      });
      this.activeService = data['resources'][0];
    });
    this.funcService.getPopularServices().subscribe((data) => {
      this.popularCategories = data['popular'];
    });
  }

  public addBookCart(type) {
    const bookData = {
      service: this.selectedService,
      date: this.bookdate,
      desc: this.bookdesc,
      time: this.booktime,
      category: this.selectedCategory
    };
    if (this.bookdate !== '' && this.booktime !== '') {
      this.funcService.addToBookCart(bookData);
      this.closeModal();
      if (type === 'book') {
        this.uiService.goToBooking();
      }
    } else if (this.bookdate === '') {
      this.uiService.showWarning('Please add booking date.')
    } else if (this.booktime === '') {
      this.uiService.showWarning('Please add booking time.')
    }
  }

  public closeModal() {
    this.selectedService = '';
    this.bookdate = '';
    this.bookdesc = '';
    this.booktime = '';
  }

  public searchingData(ev) {
    const search = ev.target.value;

    this.searchText = search;
    if (this.searchText !== '') {
      this.searchActivate = true;
    } else {
      this.searchActivate = false;
    }
  }

  public assignDates() {
    const todayNow = new Date(this.funcService.createdAt * 1000).toISOString();
    this.todaysDate = todayNow;
    const date = new Date(todayNow);
    date.setFullYear(date.getFullYear() + 2);
    const formatDate = new Date(date).toISOString();
    const newDate = this.formatDate(formatDate);
    this.maxDate = newDate;
  }

  public formatDate(value: string) {
    return format(parseISO(value), 'MMM dd yyyy');
  }

  public sendEmail() {
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*', 'content-type': 'application/json' })
    try {
      this.funcService.httpClient.post('https://ejumahub-email.herokuapp.com/sendemail', {
        email: 'codemickey78@gmail.com',
        subject: 'Running a Test',
        text: 'This is just a test verification'
      }, {
        headers: headers
      }).toPromise().then((res) => {
        console.log(res);
      })
    } catch (error) {
      console.log(error);

    }
  }
}
