<section class="sercat-main">
  <app-header></app-header>
  <div class="sctinner-main">
    <div class="container">
      <div class="row">
        <div class="col-lg-9">
          <div class="scti-top">
            <div class="scti-tinner d-flex justify-content-start">
              <div class="sct-img">
                <img src="../../../assets/img/plumber.jpg" alt="">
              </div>
              <div class="sct-info">
                <div>
                  <span>
                    <i class="fas fa-star text-primary"></i>
                    <i class="fas fa-star text-primary"></i>
                    <i class="fas fa-star text-primary"></i>
                    <i class="fas fa-star text-primary"></i>
                    <i class="fas fa-star text-primary"></i>
                  </span>
                  <span>
                    45
                    <i class="fas fa-chart-line"></i>
                  </span>
                </div>
                <h5 class="m-0 text-capitalize">plumbing</h5>
                <p class="m-0">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto quibusdam illum est, aperiam quidem
                  nesciunt
                  nam placeat nemo sint nobis aliquam velit et dolores distinctio sunt mollitia fugit minima suscipit.
                </p>
              </div>
            </div>
          </div>
          <div class="scti-bot">
            <nav class="mb-4">
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <a class="nav-item nav-link active" id="nav-services-tab" data-toggle="tab" href="#nav-services"
                  role="tab" aria-controls="nav-services" aria-selected="true">Services</a>
                <a class="nav-item nav-link" id="nav-reviews-tab" data-toggle="tab" href="#nav-reviews" role="tab"
                  aria-controls="nav-reviews" aria-selected="false">Reviews</a>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div class="tab-pane fade show active" id="nav-services" role="tabpanel"
                aria-labelledby="nav-services-tab">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="scti-ncard text-center">
                      <h6 class="m-0 text-capitalize">faucet installation</h6>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="scti-ncard text-center">
                      <h6 class="m-0 text-capitalize">pipe repair</h6>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="scti-ncard text-center">
                      <h6 class="m-0 text-capitalize">drain repair</h6>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="scti-ncard text-center">
                      <h6 class="m-0 text-capitalize">sink replacement</h6>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="scti-ncard text-center">
                      <h6 class="m-0 text-capitalize">toilet repair</h6>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="scti-ncard text-center">
                      <h6 class="m-0 text-capitalize">water closet installation</h6>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="scti-ncard text-center">
                      <h6 class="m-0 text-capitalize">faucet installation</h6>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="scti-ncard text-center">
                      <h6 class="m-0 text-capitalize">pipe repair</h6>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="scti-ncard text-center">
                      <h6 class="m-0 text-capitalize">drain repair</h6>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="scti-ncard text-center">
                      <h6 class="m-0 text-capitalize">sink replacement</h6>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="scti-ncard text-center">
                      <h6 class="m-0 text-capitalize">toilet repair</h6>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="scti-ncard text-center">
                      <h6 class="m-0 text-capitalize">water closet installation</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="nav-reviews" role="tabpanel" aria-labelledby="nav-reviews-tab">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="scti-recard">
                      <h6 class="m-0 text-capitalize">michael tetteh quaynor</h6>
                      <span class="scti-rerate">
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                      </span>
                      <p class="m-0">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores odit ad autem beatae ratione
                        similique fuga ut suscipit, ab odio voluptas.
                      </p>
                      <div class="d-flex justify-content-between">
                        <span class="badge badge-light text-capitalize">carpenter</span>
                        <span class="scti-redate text-capitalize">3rd Jun 2020</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="scti-recard">
                      <h6 class="m-0 text-capitalize">michael tetteh quaynor</h6>
                      <span class="scti-rerate">
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                      </span>
                      <p class="m-0">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores odit ad autem beatae ratione
                        similique fuga ut suscipit, ab odio voluptas.
                      </p>
                      <div class="d-flex justify-content-between">
                        <span class="badge badge-light text-capitalize">carpenter</span>
                        <span class="scti-redate text-capitalize">3rd Jun 2020</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="scti-recard">
                      <h6 class="m-0 text-capitalize">michael tetteh quaynor</h6>
                      <span class="scti-rerate">
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                      </span>
                      <p class="m-0">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores odit ad autem beatae ratione
                        similique fuga ut suscipit, ab odio voluptas.
                      </p>
                      <div class="d-flex justify-content-between">
                        <span class="badge badge-light text-capitalize">carpenter</span>
                        <span class="scti-redate text-capitalize">3rd Jun 2020</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="scti-recard">
                      <h6 class="m-0 text-capitalize">michael tetteh quaynor</h6>
                      <span class="scti-rerate">
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                      </span>
                      <p class="m-0">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores odit ad autem beatae ratione
                        similique fuga ut suscipit, ab odio voluptas.
                      </p>
                      <div class="d-flex justify-content-between">
                        <span class="badge badge-light text-capitalize">carpenter</span>
                        <span class="scti-redate text-capitalize">3rd Jun 2020</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="scti-recard">
                      <h6 class="m-0 text-capitalize">michael tetteh quaynor</h6>
                      <span class="scti-rerate">
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                      </span>
                      <p class="m-0">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores odit ad autem beatae ratione
                        similique fuga ut suscipit, ab odio voluptas.
                      </p>
                      <div class="d-flex justify-content-between">
                        <span class="badge badge-light text-capitalize">carpenter</span>
                        <span class="scti-redate text-capitalize">3rd Jun 2020</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="scti-recard">
                      <h6 class="m-0 text-capitalize">michael tetteh quaynor</h6>
                      <span class="scti-rerate">
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                        <i class="fas fa-star text-primary"></i>
                      </span>
                      <p class="m-0">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Maiores odit ad autem beatae ratione
                        similique fuga ut suscipit, ab odio voluptas.
                      </p>
                      <div class="d-flex justify-content-between">
                        <span class="badge badge-light text-capitalize">carpenter</span>
                        <span class="scti-redate text-capitalize">3rd Jun 2020</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="sc-adcmain">
            <div class="sc-adcard">
              <img src="../../../assets/img/ad/download.png" alt="">
            </div>
            <div class="sc-adcard">
              <img src="../../../assets/img/ad/handy.png" alt="">
            </div>
            <div class="sc-adcard">
              <img src="../../../assets/img/ad/appha.png" alt="">
            </div>
            <div class="sc-adcard">
              <img src="../../../assets/img/ad/fix.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</section>
