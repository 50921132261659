<div class="main-footer">
  <div class="container">
    <div class="row mb-3">
      <div class="col-lg-3 col-md-6">
        <div class="mf-left">
          <img src="../../../assets/img/logo-white.png" alt="" class="mfm-logo">
          <p class="m-0">
            EjumaHub is Ghana's leading platform for connecting individuals looking for home services with professional
            handymen and women.
          </p>
          <div class="d-flex justify-content-start apply-simg">
            <img src="../../../assets/img/psbadge.svg" class="use-pointer" alt="" (click)="launchStore('playstore')">
            <img src="../../../assets/img/asbadge.svg" class="use-pointer" alt="" (click)="launchStore('appstore')">
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="mf-header">
          <h5 class="m-0">Popular Services</h5>
        </div>
        <ul class="list-group">
          <li class="list-group-item" *ngFor="let service of subServices">
            <a [routerLink]="['/services/' + service]">{{service}}</a>
          </li>
        </ul>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="mf-header">
          <h5 class="m-0">Find More About Us</h5>
        </div>
        <ul class="list-group">
          <li class="list-group-item">
            <a [routerLink]="['/home']">Home</a>
          </li>
          <li class="list-group-item">
            <a [routerLink]="['/apply']">Become a Pro</a>
          </li>
          <li class="list-group-item">
            <a [routerLink]="['/aboutus']">About Us</a>
          </li>
          <li class="list-group-item">
            <a [routerLink]="['/contactus']">Contact Us</a>
          </li>
          <li class="list-group-item">
            <a [routerLink]="['/terms/privacy']">Privacy Policy</a>
          </li>
          <li class="list-group-item">
            <a [routerLink]="['/terms/user']">Terms & Conditions</a>
          </li>
        </ul>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="mf-header">
          <h5 class="m-0">Connect With Us</h5>
        </div>
        <ul class="list-group">
          <li class="list-group-item">
            <i class="fas fa-map-pin"></i> V324 George Bush Hwy<br>
            McCarthy Hill Junction - Accra
          </li>
          <li class="list-group-item">
            <i class="fas fa-globe"></i> Accra, Ghana
          </li>
          <li class="list-group-item">
            <i class="fas fa-phone"></i> 030 298 4047
          </li>
          <li class="list-group-item">
            <i class="fas fa-at"></i> hello@ejumahub.com
          </li>
        </ul>
        <div class="btn-group" role="group" aria-label="">
          <a href="https://web.facebook.com/ejumahub" target="_blank" rel="noopener noreferrer" class="btn btn-light">
            <i class="fab fa-facebook"></i>
          </a>
          <a href="https://instagram.com/ejumahub" target="_blank" rel="noopener noreferrer" class="btn btn-light">
            <i class="fab fa-instagram"></i>
          </a>
          <a href="https://twitter.com/ejumahub" target="_blank" rel="noopener noreferrer" class="btn btn-light">
            <i class="fab fa-twitter"></i>
          </a>
          <a href="https://www.linkedin.com/company/ejumahub" target="_blank" rel="noopener noreferrer"
            class="btn btn-light">
            <i class="fab fa-linkedin"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="text-center">
      <h6 class="m-0"><i class="fas fa-copyright"></i> Copyright 2022 <a
          href="https://ejumahub.com">EjumaHub</a>.&nbsp;All Right Reserved.&nbsp;Built by <a
          href="https://apphalabs.com" target="_blank" rel="noopener noreferrer">Appha Labs Ltd.</a>
      </h6>
    </div>
  </div>
</div>