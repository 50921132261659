export const environment = {
  production: true,
  bookCountId: 'HOTmMAsDVaWLvEaQggrL',
  userCountId: 'vbfcjMmiV1Zva6cLbgZc',
  firebase: {
    apiKey: 'AIzaSyBkFI1oLpIEMtGrHkmbPt74U4o2jj3HcEQ',
    authDomain: 'ejumahub-app.firebaseapp.com',
    databaseURL: 'https://ejumahub-app.firebaseio.com',
    projectId: 'ejumahub-app',
    storageBucket: 'ejumahub-app.appspot.com',
    messagingSenderId: '801907480578',
    appId: '1:801907480578:web:8d5888457c3d45fe1a2f1b',
    measurementId: 'G-BNDD21J23H'
  },
  payKey: 'pk_live_9c0eaf0d1bd94bfac556f867e65d020219018bfe',
  // payKey: 'pk_test_2cfdbaae00efbaf303bb4639f60c638bdf5b8931'
};
