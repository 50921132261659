import { AuthService } from './../../services/auth.service';
import { UiService } from './../../services/ui.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgotpass',
  templateUrl: './forgotpass.component.html',
  styleUrls: ['./forgotpass.component.scss']
})
export class ForgotpassComponent implements OnInit {
  public resetForm: UntypedFormGroup;
  constructor(private uiService: UiService, private authService: AuthService) { }

  ngOnInit() {
    this.resetForm = new UntypedFormGroup({
      email: new UntypedFormControl('', Validators.compose([
        Validators.required, Validators.email
      ]))
    });
  }
  public resetPassword() {
    this.authService.sendPasswordReset(this.resetForm.value.email)
  }

  public goToLogin() {
    this.uiService.goToRoute('login');
  }
}
