import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss']
})
export class ApplyComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

  public sendProRequest(e) {
    e.preventDefault();
    const ahrefElem = document.createElement('a');
    ahrefElem.href = 'https://forms.gle/fHScBuGLqodzdaPCA';
    ahrefElem.target = '_blank';
    ahrefElem.click();
  }
}
