<section class="bookings-main">
  <app-header></app-header>
  <div class="books-main">
    <div class="container">
      <app-loader></app-loader>
      <div class="row">
        <div class="col-lg-9 col-md-12">
          <div class="books-top">
            <div class="d-flex justify-content-between align-items-center">
              <h4 class="m-0">Your Address List</h4>
              <button type="button" class="btn btn-primary" (click)="changeType('add')" data-toggle="modal"
                data-target="#address" data-backdrop="static">Add Address</button>
            </div>
          </div>
          <div class="books-bot">
            <div class="empytState" *ngIf="addressList.length === 0 && emptyState === true">
              <div class="empty-card text-center">
                <img src="../../../assets/img/home.svg" alt="">
                <h5 class="m-0">
                  Your addresses are empty
                </h5>
              </div>
            </div>
            <div class="row" *ngIf="addressList.length === 0 && emptyState === null">
              <div class="col-lg-4 col-md-6" *ngFor="let address of skeletonList">
                <div class="booking-card">
                  <ngx-skeleton-loader
                    [theme]="{width: '100%','border-radius': '50px',height: '10px','margin-bottom': '5px'}">
                  </ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    [theme]="{width: '80%','border-radius': '50px',height: '10px','margin-bottom': '5px'}">
                  </ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    [theme]="{width: '60%','border-radius': '50px',height: '10px','margin-bottom': '5px'}">
                  </ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    [theme]="{width: '50%','border-radius': '50px',height: '10px','margin-bottom': '5px'}">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="addressList.length !== 0">
              <div class="col-lg-4 col-md-6" *ngFor="let address of addressList; let id = index">
                <div class="booking-card">
                  <h5 class="m-0 text-capitalize">{{address.street}}</h5>
                  <h6 class="m-0 text-capitalize">{{address.city}}</h6>
                  <h6 class="m-0 text-capitalize">{{address.region}}</h6>
                  <h6 class="m-0 text-capitalize">{{address.country}}</h6>
                  <div class="d-flex justify-content-start bphone">
                    <span class="badge badge-light">{{address.phonenum}}</span>
                  </div>
                  <div class="d-flex justify-content-end binfo">
                    <!-- <div>
                      <span class="badge badge-success">
                        <i class="fas fa-check-circle"></i>
                        Primary
                      </span>
                    </div> -->
                    <div>
                      <div class="btn-group" role="group" aria-label="">
                        <button type="button" class="btn btn-light btn-clear" (click)="changeType('edit', address)"
                          data-toggle="modal" data-backdrop="static" data-target="#address">
                          <i class="fas fa-edit"></i>
                        </button>
                        <button type="button" class="btn btn-light btn-clear" (click)="deleteAddress(address, id)">
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <app-side-promo></app-side-promo>
        </div>
      </div>
    </div>
    <div class="modal fade" id="address" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Address</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <div *ngIf="adType === 'add'">
              <form [formGroup]="addressForm">
                <div class="form-group">
                  <label for="street">Street Address</label>
                  <input formControlName="street" type="text" placeholder="Eg: Asylum Down, GA-027-9004" name="street" id="street" class="form-control">
                </div>
                <div class="form-group">
                  <label for="city">City</label>
                  <select formControlName="city" type="text" name="city" id="city" class="form-control">
                    <option *ngFor="let city of allCities" [value]="city.city">{{city.city}}</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="region">Region</label>
                  <select formControlName="region" type="text" name="region" id="region" class="form-control">
                    <option value="Greater Accra">Greater Accra</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="phonenum">Phone Number</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1">+233</span>
                    </div>
                    <input formControlName="phonenum" minlength="9" maxlength="10" type="tel" name="phonenum" id="phonenum" class="form-control">
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="saveAddress()"
                    [disabled]="!addressForm.valid" *ngIf="adType === 'add'">Save</button>
                </div>
              </form>
            </div>
            <div *ngIf="adType === 'edit'">
              <div class="form-group">
                <label for="street">Street Address</label>
                <input [(ngModel)]="street" type="text" placeholder="Eg: Asylum Down, GA-027-9004" name="street" id="street" class="form-control">
              </div>
              <div class="form-group">
                <label for="city">City</label>
                <select [(ngModel)]="city" type="text" name="city" id="city" class="form-control">
                  <option *ngFor="let city of allCities" [value]="city.city">{{city.city}}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="region">Region</label>
                <select [(ngModel)]="region" type="text" name="region" id="region" class="form-control">
                  <option selected value="Greater Accra">Greater Accra</option>
                </select>
              </div>
              <div class="form-group">
                <label for="phonenum">Phone Number</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">+233</span>
                  </div>
                  <input [(ngModel)]="phonenum" minlength="10" maxlength="10" placeholder="0546003000" type="tel" name="phonenum" id="phonenum" class="form-control">
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="editAddress()"
                  *ngIf="adType === 'edit'">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</section>