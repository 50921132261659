<section class="terms-main">
  <app-header></app-header>
  <div class="jumb-bg">
    <div class="jumb-bginner">
      <div class="container">
        <h4 class="m-0">
          Terms & Privacy
        </h4>
      </div>
    </div>
  </div>

  <div class="privacy-subheader">
    <div class="container">
      <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="home-tab" data-toggle="tab" href="#privacy" role="tab" aria-controls="privacy"
            aria-selected="true">Privacy Policy</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="terms-tab" data-toggle="tab" href="#terms" role="tab" aria-controls="terms"
            aria-selected="false">User Terms</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="payment-tab" data-toggle="tab" href="#payment" role="tab" aria-controls="payment"
            aria-selected="false">Payment Policy</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="pro-tab" data-toggle="tab" href="#worker" role="tab" aria-controls="pro"
            aria-selected="false">Service Professional Terms</a>
        </li>
      </ul>
    </div>
  </div>
  <section class="main-privacy">
    <div class="container">
      <div class="tab-content">
        <div class="tab-pane active" id="privacy" role="tabpanel" aria-labelledby="privacy-tab">
          <div class="privacy-grand">
            <h3 class="m-0">Privacy Policy</h3>
            <p class="m-0">Last Updated: January 12, 2022</p>
          </div>
          <div class="privacy-desc">
            <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
              information when You use the Service and tells You about Your privacy rights and how the law protects You.
            </p>
            <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
              collection
              and use of information in accordance with this Privacy Policy.</p>
            <h5>Interpretation and Definitions</h5>
            <h2>Interpretation</h2>
            <p>The words of which the initial letter is capitalized have meanings defined under the following
              conditions.
              The following definitions shall have the same meaning regardless of whether they appear in singular or in
              plural.</p>
            <h2>Definitions</h2>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
              <li>
                <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other
                  legal
                  entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
              </li>
              <li>
                <p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
                  refers to Appha Labs Ltd., V324 George Bush Hwy
                  McCarthy Hill Junction - Accra, Ghana.</p>
              </li>
              <li>
                <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control
                  with
                  a party, where "control" means ownership of 50% or more of the shares, equity interest or other
                  securities
                  entitled to vote for election of directors or other managing authority.</p>
              </li>
              <li>
                <p><strong>Account</strong> means a unique account created for You to access our Service or parts of our
                  Service.</p>
              </li>
              <li>
                <p><strong>Website</strong> refers to EjumaHub, accessible from <a href="https://ejumahub.com"
                    rel="external nofollow noopener" target="_blank">https://ejumahub.com</a></p>
              </li>
              <li>
                <p><strong>Service</strong> refers to the Website.</p>
              </li>
              <li>
                <p><strong>Country</strong> refers to: Ghana</p>
              </li>
              <li>
                <p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf
                  of the
                  Company. It refers to third-party companies or individuals employed by the Company to facilitate the
                  Service, to provide the Service on behalf of the Company, to perform services related to the Service
                  or to
                  assist the Company in analyzing how the Service is used.</p>
              </li>
              <li>
                <p><strong>Third-party Social Media Service</strong> refers to any website or any social network website
                  through which a User can log in or create an account to use the Service.</p>
              </li>
              <li>
                <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable
                  individual.
                </p>
              </li>
              <li>
                <p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other
                  device by a website, containing the details of Your browsing history on that website among its many
                  uses.
                </p>
              </li>
              <li>
                <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone
                  or a
                  digital tablet.</p>
              </li>
              <li>
                <p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of
                  the
                  Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
              </li>
            </ul>
            <h5>Collecting and Using Your Personal Data</h5>
            <h2>Types of Data Collected</h2>
            <h3>Personal Data</h3>
            <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information
              that can
              be used to contact or identify You. Personally identifiable information may include, but is not limited
              to:</p>
            <ul>
              <li>
                <p>Email address</p>
              </li>
              <li>
                <p>First name and last name</p>
              </li>
              <li>
                <p>Phone number</p>
              </li>
              <li>
                <p>Address, State, Province, ZIP/Postal code, City</p>
              </li>
              <li>
                <p>Usage Data</p>
              </li>
            </ul>
            <h3>Usage Data</h3>
            <p>Usage Data is collected automatically when using the Service.</p>
            <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
              browser
              type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time
              spent
              on those pages, unique device identifiers and other diagnostic data.</p>
            <p>When You access the Service by or through a mobile device, We may collect certain information
              automatically,
              including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP
              address
              of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique
              device
              identifiers and other diagnostic data.</p>
            <p>We may also collect information that Your browser sends whenever You visit our Service or when You access
              the
              Service by or through a mobile device.</p>
            <h3>Tracking Technologies and Cookies</h3>
            <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain
              information. Tracking technologies used are beacons, tags, and scripts to collect and track information
              and to
              improve and analyze Our Service.</p>
            <p>You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However,
              if You
              do not accept Cookies, You may not be able to use some parts of our Service.</p>
            <p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or
              mobile
              device when You go offline, while Session Cookies are deleted as soon as You close your web browser. Learn
              more
              about cookies: <a href="https://www.termsfeed.com/blog/cookies/" target="_blank">All About Cookies</a>.
            </p>
            <p>We use both session and persistent Cookies for the purposes set out below:</p>
            <ul>
              <li>
                <p><strong>Necessary / Essential Cookies</strong></p>
                <p>Type: Session Cookies</p>
                <p>Administered by: Us</p>
                <p>Purpose: These Cookies are essential to provide You with services available through the Website and
                  to
                  enable You to use some of its features. They help to authenticate users and prevent fraudulent use of
                  user
                  accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only
                  use
                  these Cookies to provide You with those services.</p>
              </li>
              <li>
                <p><strong>Cookies Policy / Notice Acceptance Cookies</strong></p>
                <p>Type: Persistent Cookies</p>
                <p>Administered by: Us</p>
                <p>Purpose: These Cookies identify if users have accepted the use of cookies on the Website.</p>
              </li>
              <li>
                <p><strong>Functionality Cookies</strong></p>
                <p>Type: Persistent Cookies</p>
                <p>Administered by: Us</p>
                <p>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as
                  remembering
                  your login details or language preference. The purpose of these Cookies is to provide You with a more
                  personal experience and to avoid You having to re-enter your preferences every time You use the
                  Website.</p>
              </li>
            </ul>
            <p>For more information about the cookies we use and your choices regarding cookies, please visit our
              Cookies
              Policy or the Cookies section of our Privacy Policy.</p>
            <h2>Use of Your Personal Data</h2>
            <p>The Company may use Personal Data for the following purposes:</p>
            <ul>
              <li><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.
              </li>
              <li><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The
                Personal
                Data You provide can give You access to different functionalities of the Service that are available to
                You as
                a registered user.</li>
              <li><strong>For the performance of a contract:</strong> the development, compliance and undertaking of the
                purchase contract for the products, items or services You have purchased or of any other contract with
                Us
                through the Service.</li>
              <li><strong>To contact You:</strong> To contact You by email, telephone calls, SMS, or other equivalent
                forms of
                electronic communication, such as a mobile application's push notifications regarding updates or
                informative
                communications related to the functionalities, products or contracted services, including the security
                updates, when necessary or reasonable for their implementation.</li>
              <li><strong>To provide You</strong> with news, special offers and general information about other goods,
                services and events which we offer that are similar to those that you have already purchased or enquired
                about
                unless You have opted not to receive such information.</li>
              <li><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</li>
            </ul>
            <p>We may share your personal information in the following situations:</p>
            <ul>
              <li><strong>With Service Providers:</strong> We may share Your personal information with Service Providers
                to
                monitor and analyze the use of our Service, to contact You.</li>
              <li><strong>For Business transfers:</strong> We may share or transfer Your personal information in
                connection
                with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or
                a
                portion of our business to another company.</li>
              <li><strong>With Affiliates:</strong> We may share Your information with Our affiliates, in which case we
                will
                require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any
                other
                subsidiaries, joint venture partners or other companies that We control or that are under common control
                with
                Us.</li>
              <li><strong>With Business partners:</strong> We may share Your information with Our business partners to
                offer
                You certain products, services or promotions.</li>
              <li><strong>With other users:</strong> when You share personal information or otherwise interact in the
                public
                areas with other users, such information may be viewed by all users and may be publicly distributed
                outside.
                If You interact with other users or register through a Third-Party Social Media Service, Your contacts
                on the
                Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity.
                Similarly, other users will be able to view descriptions of Your activity, communicate with You and view
                Your
                profile.</li>
            </ul>
            <h2>Retention of Your Personal Data</h2>
            <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in
              this
              Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal
              obligations (for example, if we are required to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.</p>
            <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained
              for a
              shorter period of time, except when this data is used to strengthen the security or to improve the
              functionality
              of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
            <h2>Transfer of Your Personal Data</h2>
            <p>Your information, including Personal Data, is processed at the Company's operating offices and in any
              other
              places where the parties involved in the processing are located. It means that this information may be
              transferred to — and maintained on — computers located outside of Your state, province, country or other
              governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
            <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your
              agreement
              to that transfer.</p>
            <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in
              accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an
              organization or
              a country unless there are adequate controls in place including the security of Your data and other
              personal
              information.</p>
            <h2>Disclosure of Your Personal Data</h2>
            <h3>Business Transactions</h3>
            <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred.
              We
              will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy
              Policy.
            </p>
            <h3>Law enforcement</h3>
            <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do
              so by
              law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
            <h3>Other legal requirements</h3>
            <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
            </p>
            <ul>
              <li>Comply with a legal obligation</li>
              <li>Protect and defend the rights or property of the Company</li>
              <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
              <li>Protect the personal safety of Users of the Service or the public</li>
              <li>Protect against legal liability</li>
            </ul>
            <h2>Security of Your Personal Data</h2>
            <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over
              the
              Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable
              means
              to protect Your Personal Data, We cannot guarantee its absolute security.</p>
            <h5>Links to Other Websites</h5>
            <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third
              party
              link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy
              of
              every site You visit.</p>
            <p>We have no control over and assume no responsibility for the content, privacy policies or practices of
              any
              third party sites or services.</p>
            <h5>Changes to this Privacy Policy</h5>
            <p>We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new
              Privacy Policy on this page.</p>
            <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
              effective
              and update the "Last updated" date at the top of this Privacy Policy.</p>
            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
              Policy are
              effective when they are posted on this page.</p>
            <h5>Contact Us</h5>
            <p>If you have any questions about this Privacy Policy, You can contact us:</p>
            <ul><li>By visiting this page on our website: <a href="https://ejumahub.com/contactus" rel="external nofollow noopener"
              target="_blank">Click here</a></li>
            </ul>
          </div>
        </div>
        <div class="tab-pane" id="terms" role="tabpanel" aria-labelledby="terms-tab">
          <div class="privacy-grand">
            <h3 class="m-0">Terms & Conditions</h3>
            <p class="m-0">Last Updated: January 12, 2022</p>
          </div>
          <div class="privacy-desc">
            <p>Please read these terms and conditions carefully before using Our Service.</p>
            <h5>Interpretation and Definitions</h5>
            <h2>Interpretation</h2>
            <p>The words of which the initial letter is capitalized have meanings defined under the following
              conditions.</p>
            <p>The following definitions shall have the same meaning regardless of whether they appear in singular or in
              plural.</p>
            <h2>Definitions</h2>
            <p>For the purposes of these Terms and Conditions:</p>
            <ul>
              <li>
                <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control
                  with
                  a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or
                  other
                  securities entitled to vote for election of directors or other managing authority.</p>
              </li>
              <li>
                <p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;,
                  &quot;Us&quot; or
                  &quot;Our&quot; in this Agreement) refers to Appha Labs Ltd., V324 George Bush Hwy
                  McCarthy Hill Junction - Accra, Ghana.</p>
              </li>
              <li>
                <p><strong>Country</strong> refers to: Ghana</p>
              </li>
              <li>
                <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone
                  or a
                  digital tablet.</p>
              </li>
              <li>
                <p><strong>Service</strong> refers to the Website.</p>
              </li>
              <li>
                <p><strong>Terms and Conditions</strong> (also referred as &quot;Terms&quot;) mean these Terms and
                  Conditions
                  that form the entire agreement between You and the Company regarding the use of the Service. </p>
              </li>
              <li>
                <p><strong>Third-party Social Media Service</strong> means any services or content (including data,
                  information, products or services) provided by a third-party that may be displayed, included or made
                  available by the Service.</p>
              </li>
              <li>
                <p><strong>Website</strong> refers to EjumaHub, accessible from <a href="https://ejumahub.com"
                    rel="external nofollow noopener" target="_blank">https://ejumahub.com</a></p>
              </li>
              <li>
                <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other
                  legal
                  entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
              </li>
            </ul>
            <h5>Acknowledgment</h5>
            <p>These are the Terms and Conditions governing the use of this Service and the agreement that operates
              between
              You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding
              the
              use of the Service.</p>
            <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these
              Terms and
              Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the
              Service.
            </p>
            <p>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree
              with any
              part of these Terms and Conditions then You may not access the Service.</p>
            <p>You represent that you are over the age of 18. The Company does not permit those under 18 to use the
              Service.
            </p>
            <p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the
              Privacy
              Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and
              disclosure of Your personal information when You use the Application or the Website and tells You about
              Your
              privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our
              Service.
            </p>
            <h5>Links to Other Websites</h5>
            <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by
              the
              Company.</p>
            <p>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or
              practices
              of any third party web sites or services. You further acknowledge and agree that the Company shall not be
              responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or
              in
              connection with the use of or reliance on any such content, goods or services available on or through any
              such
              web sites or services.</p>
            <p>We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites
              or
              services that You visit.</p>
            <h5>Termination</h5>
            <p>We may terminate or suspend Your access immediately, without prior notice or liability, for any reason
              whatsoever, including without limitation if You breach these Terms and Conditions.</p>
            <p>Upon termination, Your right to use the Service will cease immediately.</p>
            <h5>Governing Law</h5>
            <p>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of
              the
              Service. Your use of the Application may also be subject to other local, state, national, or international
              laws.
            </p>
            <h5>Changes to These Terms and Conditions</h5>
            <p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision
              is
              material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking
              effect. What constitutes a material change will be determined at Our sole discretion.</p>
            <p>By continuing to access or use Our Service after those revisions become effective, You agree to be bound
              by the
              revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website
              and the
              Service.</p>
            <h5>Contact Us</h5>
            <p>If you have any questions about these Terms and Conditions, You can contact us:</p>
            <ul><li>By visiting this page on our website: <a href="https://ejumahub.com/contactus" rel="external nofollow noopener"
              target="_blank">Click here</a></li>
            </ul>
          </div>
        </div>
        <div class="tab-pane" id="payment" role="tabpanel" aria-labelledby="payment-tab">
          <div class="privacy-grand">
            <h3 class="m-0">Payment Policy</h3>
            <p class="m-0">Last Updated: January 12, 2022</p>
          </div>
          <div class="privacy-desc">
            <p>
              Our policies are designed to promote a reliable, consistent experience for customers and service
              professionals alike.
              When a service is completed, customers are required to make payment directly to EjumaHub via our in-app
              payment channels, which are provided in the booking detail and can be accessed by hitting the “Pay” tab.

            </p>
            <h5>Contact Us</h5>
            <p>If you have any questions about this Privacy Policy, You can contact us:</p>
            <ul>
              <li>By visiting this page on our website: <a href="https://ejumahub.com/contactus" rel="external nofollow noopener"
                  target="_blank">Click here</a></li>
            </ul>
          </div>
        </div>
        <div class="tab-pane" id="worker" role="tabpanel" aria-labelledby="worker-tab">
          <div class="privacy-grand">
            <h3 class="m-0">Service Professionals Agreement</h3>
            <p class="m-0">Last Updated: January 12, 2022</p>
          </div>
          <div class="privacy-desc">
            <h2>SCHEDULE 1</h2>
            <p>Service Professional acknowledges he or she is, and any assistants, helpers, subcontractors or other
              personnel engaged by Service Professional are qualified and otherwise authorized to provide, among the
              Services listed below, only those Services selected by the Service Professional during the sign-up process
              for which the Service Professional is approved:</p>

            <ul>
              <li>
                <p>Home & Office Cleaning</p>
              </li>
              <li>
                <p>Fabric Cleaning</p>
              </li>
              <li>
                <p>Decoration & Design</p>
              </li>
              <li>
                <p>Furniture Assembly</p>
              </li>
              <li>
                <p>Hanging Pictures and Shelves</p>
              </li>
              <li>
                <p>Mounting Televisions</p>
              </li>
              <li>
                <p>Moving Help</p>
              </li>
              <li>
                <p>TV Installation – Electronics</p>
              </li>
              <li>
                <p>Home Improvement</p>
              </li>
              <li>
                <p>Window Treatments</p>
              </li>
              <li>
                <p>Computer & Network Services</p>
              </li>
              <li>
                <p>Air Condition Work</p>
              </li>
              <li>
                <p>Window Treatments</p>
              </li>
              <li>
                <p>Mirror Fitting</p>
              </li>
              <li>
                <p>Masonry & Concrete Work</p>
              </li>
              <li>
                <p>Painting & Wallpaper</p>
              </li>
              <li>
                <p>Plumbing Work</p>
              </li>
              <li>
                <p>Smart Home Installations</p>
              </li>
              <li>
                <p>Electrical Work</p>
              </li>
              <li>
                <p>Tile, Marble & Countertop Work</p>
              </li>
              <li>
                <p>Auto Mechanic</p>
              </li>
              <li>
                <p>Welding</p>
              </li>
              <li>
                <p>Lawn Care & Gardening Work</p>
              </li>
              <li>
                <p>Other</p>
              </li>
            </ul>
            <h2>Service Professional Agreement</h2>
            <p>APPHA LABS LIMITED</p>
            <p>SERVICE PROFESSIONAL AGREEMENT</p>
            <p>This Service Professional Agreement (the Agreement) sets forth the terms and conditions whereby you, an
              independently-established home service provider, qualified and customarily engaged in a trade, occupation
              and/or business to provide the services contemplated by this Agreement (the Service Professional), agree
              to provide certain services (as described on Schedule 1) via the web and app based platform (EjumaHub) of
              Appha Labs Limited (the company), a Ghanaian company, with a physical address at V324 George Bush Hwy
              McCarthy Hill Junction - Accra, Ghana.</p>
            <p><strong>IMPORTANT: PLEASE REVIEW THIS AGREEMENT CAREFULLY.</strong></p>
            <h5>1. GENERAL PROVISIONS</h5>
            <h5 class="sub-title">1.1 Background Statement.</h5>
            <p>Appha Labs is a technology company and its product, EjumaHub is a technology platform that creates a
              marketplace to connect potential clients (Service Requesters) with qualified professionals, such as
              Service Professional, in the business of providing home and/or office handyman services, cleaning,
              plumbing services, electrical services, painting services, lawn care, home improvement and/or moving
              services, etc. (Services). EjumaHub provides a web-based and mobile application-based portal (the EjumaHub
              Platform) through which Service Requesters contract with the company and EjumaHub fulfills using a network
              of Service Professionals. Service Professional understands and intends that Service Professional will
              provide the services to Service Requesters strictly as an independently-owned and operated business
              enterprise, and not as an employee, worker, agent, joint venturer, partner or franchisee of Appha Labs or
              any Service Requester for any purpose.
            </p>
            <h5 class="sub-title">1.2 Background Checks.</h5>
            <p>Before receiving access to the EjumaHub Platform, Service Professional must submit to and pass a
              background check through EjumaHub’s background check program. After receiving acceptance to the EjumaHub
              Platform, EjumaHub may from time-to-time, in its reasonable discretion and in accordance with applicable
              law, conduct additional background checks on Service Professional through EjumaHub’s background check
              program. EjumaHub conducts background checks in accordance with applicable law.
            </p>
            <h5>2. THE SERVICES</h5>
            <p>Service Professional shall be eligible to be assigned Jobs through the EjumaHub Platform requesting any
              Services that Service Professional is qualified to provide as specified on Schedule 1 to this Agreement
              and as selected by the Service Professional during the sign-up process. In those instances where a
              license, permit, or certification is required to perform the Services, Service Professional shall, upon
              reasonable request, provide proof to EjumaHub of all necessary licenses, permits and/or certifications
              before Service Professional provides any such Services under this Agreement.
            </p>
            <h5 class="sub-title">2.1 Job Completion.</h5>
            <p>Once Service Professional has accepted and been assigned a Job, Service Professional is contractually
              obligated to complete the Job within the Timeframe specified by, and to the satisfaction of, the Service
              Requester. Service Professional may not cancel the Job without advance notice of at least 24 hours to
              Service Requester and the company, except in the case of an unavoidable emergency, in which case, Service
              Professional shall notify Service Requester and the company as soon as practicable. Repeated cancellations
              and/or rescheduled Jobs on short-notice by Service Professional may result in termination of this
              Agreement in accordance with Section 7.
            </p>
            <p>The company is interested only in the results to be achieved by Service Professional: completion of each
              accepted Job in accordance with Service Requester's specifications as detailed by Service Requester.
              Service Professional is solely responsible for determining the manner and method of performing all Jobs
              under this Agreement.
            </p>
            <h5 class="sub-title">2.2 Service Requester Ratings.</h5>
            <p>Service Professional acknowledges that the EjumaHub Platform is available only to those Service
              Professionals who maintain high standards of professionalism and quality of service. Service Professional
              acknowledges that Service Requester may rate and review a Service Professional at the end of every
              booking. To ensure that the EjumaHub Platform remains a reliable platform, Service Professional agrees to
              maintain a Service Requester rating at or above the minimum rating established by EjumaHub for access to
              the EjumaHub Platform, as modified from time to time. In the event a Service Professional's aggregate
              rating falls below the applicable minimum rating, EjumaHub reserves the right to deactivate the Service
              Professional's access to the EjumaHub Platform. EjumaHub agrees to provide Service Professional notice of
              the minimum rating and any changes thereto.
            </p>
            <h5 class="sub-title">2.3 No Control.</h5>
            <p>Service Professional shall be, at all times while this Agreement is in effect, both under the terms of
              this Agreement and in fact, free from the company's supervision, control and direction in the performance
              of the Services. The company shall not control or have any right to control the manner or means by which
              Service Professional performs the Services, including but not limited to the time and place Service
              Professional performs the Services, the Jobs Service Professional selects, the tools and materials used by
              Service Professional to complete the Jobs, the helpers, assistants, subcontractors or other personnel (if
              any) used by Service Professional in completing Jobs, or the manner in which Service Professional
              completes the Jobs. The company will not and has no right to, under any circumstances, require Service
              Professional to participate in training, nor does the company offer or provide training to Service
              Professional.
            </p>
            <p>Service Professional is not obligated to personally perform the Services. Service Professional shall
              furnish at his/her own discretion, selection, and expense any and all assistants, helpers, subcontractors
              or other personnel the Service Professional deems necessary and appropriate to complete the Services.
              Service Professional shall be solely responsible for the direction and control of any such personnel and
              for all acts and omissions of same.
            </p>
            <p>Before any Services are performed by any assistants, helpers, subcontractors or other personnel engaged
              by Service Professional, Service Professional shall require any such individuals to submit to a basic
              background check satisfactory to EjumaHub.
            </p>
            <p>Service Professional assumes full and sole responsibility for the payment of all compensation, benefits
              and expenses of helpers, assistants, subcontractors and/or other personnel, if any, and for all required
              income tax withholdings, as to Service Professional and all persons engaged by Service Professional in the
              performance of the Services. Service Professional agrees that he/she is fully and solely responsible for
              filing, and shall file, an appropriate schedule of expenses and all other appropriate tax documents with
              the Ghana Revenue Authority in compliance with required filing periods. Service Professional shall be
              responsible for, and shall indemnify and hold the company harmless for any claims, suits, or actions
              related to this provision, including any such claims brought by Service Professional or his or her
              assistants, subcontractors and/or other personnel, or by any third party with respect to any claims for
              taxes or contributions, including penalties and interest.
            </p>
            <p>To the extent required by applicable law, Service Professional agrees to maintain a separate set of books
              or records reflecting all items of income and expense associated with operating his or her business.
            </p>
            <h5>3. SERVICES FEES</h5>
            <h5 class="sub-title">3.1 Service Fees.</h5>
            <p>Service Fee for each Job shall be set by the Company and the Service Professional at the time the Job is
              posted on the EjumaHub Platform, which shall be based on the stated parameters of the Job. Each Job made
              available to Service Professional on the Platform shall set forth the Timeframe and details about the
              Service requested. The Company shall be entitled to a Ten Percent (10%) of the set service fee for each
              job, which constitutes the Booking Fee, whereas the Service Professional shall be entitled to Ninety
              Percent (90%) of the same service fee upon completion of the Job, as modified from time to time. Service
              Requester shall pay for completed Jobs to the company directly via in-app payment channels and in the
              event that a Service Requester is unable to make payment via in-app payment channels, Service Professional
              must ensure the Service Requester contacts the Company to be provided another payment option. Service
              Professional must at all times, confirm with the Company that completed Jobs has been paid for before
              leaving the scene of the Job.
            </p>
            <p>Modifications to percentages and fee parameters will be effective upon written notification to Service
              Professional and will supersede any and all prior versions. By accepting this Agreement, Service
              Professional authorizes the Company to withhold EjumaHub's Booking Fee and any other applicable fees from
              the Service Fee before remitting to the Service Professional the Percentage or Fee to which Service
              Professional is entitled for each Job.
            </p>
            <h5 class="sub-title">3.2 Service Fee Payment.</h5>
            <p>When a Job is completed, Service Requester will make payment to the Company and Service Professional will
              confirm payment with the Company before leaving the Job scene. The company will transmit payment to
              Service Professional, whether in Service Professional's capacity as a sole proprietorship or other
              corporate entity, via mobile money payment. So long as Service Professional has completed the steps
              necessary to set up a mobile money account and provided those details to the company, the company shall
              then remit payment for each Job with Service Professional bearing charges of payment where applicable,
              less EjumaHub's Booking Fee and any other applicable fees on the same day as the Job was completed. If
              applicable, the company will report the payments paid to Service Professional under this Agreement by
              filing the appropriate form with the Ghana Revenue Service as required by law.
            </p>
            <h5>4. EQUIPMENT AND OPERATIONS</h5>
            <h5 class="sub-title">4.1 Costs of Operation.</h5>
            <p>Service Professional is solely responsible for any costs or expenses incurred by Service Professional in
              connection with the operation of Service Professional’s business and the performance of the Services.
              Service Professional shall furnish and maintain, at Service Professional's own expense, the tools,
              equipment, supplies, and other materials used to perform the Services. Service Professional, at Service
              Professional's sole discretion, shall determine what equipment, supplies, and materials are necessary to
              perform the Services, and where, when, and at what cost, to purchase or maintain any necessary equipment,
              supplies, tools, and materials.
            </p>
            <h5 class="sub-title">4.2 EjumaHub Apparel or Identification.</h5>
            <p>Service Professional will have no obligation to wear or display EjumaHub branded apparel, badge or other
              form of identification.
            </p>
            <h5 class="sub-title">4.3 Use of Voice, Image and Likeness.</h5>
            <p>Service Professional gives EjumaHub permission to use any and all of Service Professional's voice, image,
              likeness, and any ratings and reviews from Service Requesters about Service Professional, with or without
              using Service Professional's name, in connection with the products and/or services available through the
              EjumaHub Platform, for the purposes of advertising and promoting such products and/or services and/or
              EjumaHub, for the purposes of identifying Service Professional to Service Requester, and/or for other
              purposes deemed appropriate by the EjumaHub in its reasonable discretion, except to the extent expressly
              prohibited by law. In addition to the foregoing, Service Professional may be required to submit an image
              for use by the EjumaHub Platform to facilitate identifying Service Professional with Service Requesters.
              Service Professional also represents and warrants the Service Professional owns the copyright of any image
              or likeness that the Service Professional provides to EjumaHub.
            </p>
            <h5>5. RELATIONSHIP OF THE PARTIES</h5>
            <p>Service Professional is an independent contractor. Service Professional represents that he or she is
              customarily engaged in an independently established trade, occupation, profession and/or business offering
              the Services to the general public and/or Service Professional represents that he or she maintains a
              principal place of business in connection with Service Professional’s trade, occupation, profession and/or
              business that is eligible for a business deduction for income tax purposes. This Agreement shall not be
              construed to create any association, partnership, joint venture, employee, worker or agency relationship
              between Service Professional and Appha Labs Ltd. or any Service Requester for any purpose. Service
              Professional has no authority (and shall not hold himself or herself out as having authority) to bind the
              company and Service Professional shall not make any agreements or representations on the company's behalf
              without the company's prior written consent. Service Professional understands that Service Professional
              will not be eligible to participate in any benefit plans offered to the company's employees, including,
              but not limited to, vacation, group medical or life insurance, disability, profit sharing or retirement
              benefits or any other fringe benefits or benefit plans offered by the company to its employees.
              <strong>Appha Labs will not be responsible for withholding or paying any income, payroll, or other taxes,
                making any insurance contributions, including unemployment or disability, or obtaining workers'
                compensation insurance on Service Professional's behalf</strong>. Service Professional shall be
              responsible for, and shall indemnify and hold the company harmless for any claims, suits, or actions
              related to this provision, including any such claims brought by Service Professional or by any third party
              with respect to any claims for taxes or contributions, including penalties and interest
            </p>
            <h5>6. INDEMNIFICATION</h5>
            <p>Service Professional shall defend, indemnify and hold harmless the company and its respective officers,
              directors, employees, agents, successors, and assigns and any other entity that requests or is in any way
              involved in making available, arranging and/or facilitating services through the EjumaHub Platform from
              and against all losses, damages, liabilities, deficiencies, actions, judgments, interest, awards,
              penalties, fines, costs, or expenses of whatever kind (including reasonable legal fees) arising out of or
              resulting from: (a) bodily injury, death of any person, theft or damage to real or tangible, personal
              property resulting from Service Professional's acts or omissions, which shall include any acts or
              omissions of any assistants, helpers, subcontractors or other personnel engaged by Service Professional;
              and (b) Service Professional's breach of any representation, warranty, or obligation under this Agreement.
            </p>
            <p>The Services that Service Professional provides pursuant to this Agreement are fully and entirely Service
              Professional's responsibility. The company is not responsible or liable for the actions or inactions of a
              Service Requester or other third party in relation to the Services provided by Service Professional.
              Service Professional understands, therefore, that by using the EjumaHub Platform, Service Professional
              will be introduced to third parties in relation to whom the company has not conducted any background or
              reference checking, that may be potentially dangerous, and that Service Professional uses the EjumaHub
              Platform at his/her own risk.
            </p>
            <h5>7. TERM; TERMINATION</h5>
            <h5 class="sub-title">7.1 Term.</h5>
            <p>This Agreement shall be effective as of the date it is executed by Service Professional and shall remain
              in effect unless and until terminated as set forth in this Section 7 (the Term).
            </p>
            <h5 class="sub-title">7.2 Termination.</h5>
            <p>(a) Appha Labs and Service Professional may terminate this Agreement, effective immediately upon written
              notice to the other party, in the event that other party materially breaches this Agreement. A material
              breach shall include, but not be limited to, the acts or omissions expressly defined as constituting a
              material breach herein, misconduct, the company's failure to timely remit Service Fees as described
              herein, Service Professional's repeated failure to complete a Job he or she has accepted and been assigned
              on the platform to the Service Requester’s satisfaction, Service Professional's failure to meet the
              applicable minimum rating, or if a Service Professional cancels or reschedules two (2) or more Jobs he or
              she has been assigned and accepted on less than 2 hours' notice prior to the applicable Job start time
              within any twenty-eight (28) day period.
            </p>
            <p>(b) In addition to the foregoing, Service Professional may terminate the Agreement for any reason upon
              fifteen (15) days' written notice.
            </p>
            <h5 class="sub-title">7.3 Service Professional's Obligations Upon Termination.</h5>
            <p>Upon termination of this Agreement for any reason, Service Professional shall: (a) complete any
              outstanding Jobs Service Professional has accepted and been assigned (the Outstanding Jobs) and (b) notify
              the company that Service Professional has complied with the requirements of this Section.
            </p>
            <h5 class="sub-title">7.4 Appha Lab's Obligations Upon Termination.</h5>
            <p>Upon termination of this Agreement: (a) if the termination is effected by the company, the company shall
              immediately pay to Service Professional any outstanding earned Service Fees; or (b) if the termination is
              effected by Service Professional, the company shall pay to Service Professional any outstanding earned
              Service Fees within five (5) business days. In either event, the company shall pay Service Fees for any
              Outstanding Jobs as soon as practicable after Service Professional has completed the Outstanding Jobs.
            </p>
            <h5 class="sub-title">7.5 Surviving Provisions.</h5>
            <p>The terms and conditions of this Section 7.5 and Sections 5, 6, 7.3, 7.4 and 8 shall survive the
              expiration or termination of this Agreement.
            </p>
            <h5>8. OTHER BUSINESS ACTIVITIES; NON-EXCLUSIVITY</h5>
            <p>Service Professional may be engaged or employed in any other business, trade, profession, or other
              activity, including providing Services to customers booked through means other than the EjumaHub Platform,
              including other web-based portals, smartphone applications, and/or platforms. The company has no
              obligation to utilize Service Professional under this Agreement and may use other providers to perform the
              same or similar services as those that may be performed by Service Professional under this Agreement.
            </p>
            <h5>9. SERVICE REQUESTER PRIVACY</h5>
            <p>Service Professional understands that in performing the Services, he or she will receive certain private
              and/or confidential information regarding the Service Requesters and will have access to their homes and
              personal belongings. Except upon order of government authority (e.g., court, administrative agency) having
              jurisdiction, or upon written consent by the Service Requester, Service Professional agrees that he or she
              shall not publish, disseminate or disclose, for his or her own benefit or the benefit of any third party,
              any confidential information regarding the Service Requesters, including addresses, telephone numbers
              and/or financial information. Service Professional further agrees not to engage in any activity which
              violates the privacy of any Service Requester, including, but not limited to, taking unauthorized
              photographs or making unauthorized audio or video recordings of a Service Requester or his or her homes or
              personal belongings, or publishing, disseminating or disclosing any such photographs or recordings.
              Service Professional acknowledges that his/her failure to comply with the foregoing shall constitute a
              material breach of this Agreement.
            </p>
            <h5>10. MODIFICATION</h5>
            <p>Service Professional hereby expressly acknowledges and agrees that, by using or receiving access to the
              EjumaHub Platform, Service Professional and Appha Labs are bound by the then-current version of this
              Agreement, including any supplements to this Agreement or documents incorporated herein, including the
              Schedule below. Service Professional shall be bound by modifications to this Agreement only upon Service
              Professional's writing signed or electronically signing any modifications or supplements.
            </p>
            <h5>11. MISCELLANEOUS</h5>
            <p>This Agreement, together with any other documents incorporated herein by reference, and related exhibits,
              schedules, and addenda (if any) constitutes the sole and entire agreement of the parties to this Agreement
              with respect to the subject matter contained herein, and supersedes all prior and contemporaneous
              understandings, agreements, representations and warranties, both written and oral, with respect to such
              subject matter. This Agreement may only be amended, modified, or supplemented by an agreement in writing
              signed by each party hereto, and any of the terms thereof may be waived, only by a written document signed
              by each party to this Agreement or, in the case of waiver, by the party or parties waiving compliance.
            </p>
            <p>This Agreement may not be amended, by implication or otherwise, by any marketing material contained on
              EjumaHub's website or the EjumaHub Platform. Nothing contained in this provision or this Agreement is
              intended to or shall be interpreted to create any third-party beneficiary claims.
            </p>
            <p>Service Professional acknowledges that he/she has read and understands this Agreement and also
              acknowledges that he/she had a reasonable and adequate opportunity to seek and receive independent legal
              advice, at the Service Professional's own expense, prior to signing this Agreement.
            </p>
            <p><strong>BY CHECKING THE BOX STATING I AGREE TO THE SERVICE PROFESSIONAL AGREEMENT, YOU HEREBY AGREE TO
                THIS SERVICE PROFESSIONAL AGREEMENT AND AGREE TO ACT IN ACCORDANCE WITH THE TERMS SET FORTH HEREIN.
              </strong></p>
            <p><strong>You understand that your electronic signature is as legally binding as a handwritten signature.
              </strong></p>
            <p>Last updated: January 12, 2022
            </p>


          </div>
        </div>
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</section>