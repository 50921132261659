<section class="loginmain">
  <app-header></app-header>
  <div class="loginbot">
    <div class="container">
      <app-loader></app-loader>
      <div class="loginb-main">
        <div class="lbm-header text-center">
          <h5 class="m-0">Forgot Password?</h5>
          <p class="m-0">Enter your email address to receive a link to reset password.</p>
        </div>
        <div class="lbm-form">
          <form [formGroup]="resetForm">
            <div class="form-group">
              <input type="email" formControlName="email" class="form-control" name="" id="" placeholder="Email">
            </div>
            <div class="form-group">
              <button type="button" [disabled]="!resetForm.value" (click)="resetPassword()" class="btn btn-primary btn-block">Reset Password</button>
            </div>
          </form>
        </div>
        <div class="lbm-options text-center">
          <button type="button" class="btn btn-light btn-block" (click)="goToLogin()">
            Sign In
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</section>
