<section class="home-main">
  <app-header></app-header>
  <div class="hmj-search">
    <div class="hmjs-inner">
      <h4 class="m-0 ">
        LEAVE THE WORK TO US. <br>
        BOOK A PRO!
      </h4>
      <div class="selj-search text-center">
        <div class="row">
          <div class="col-lg-8 p-0">
            <select class="custom-select" (change)="changeOption($event)">
              <option selected>What service do you need?</option>
              <option [value]="category?.title" *ngFor="let category of serviceCategories; let id = index">
                {{category?.title}}</option>
            </select>
          </div>
          <div class="col-lg-4 p-0">
            <button type="button" class="btn btn-primary border-0 btn-block" (click)="goToCategory()">Book</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="hmj-info">
    <div class="container">
      <div class="apply-info">
        <div class="row">
          <div class="col-lg-4 col-md-4">
            <div class="apmi-card">
              <img src="assets/img/brainstorm.svg" alt="">
              <h6 class="m-0">Skilled Specialists</h6>
              <p class="m-0">
                All our handymen are well trained and skilled. They are further background-checked and are honest and
                reliable.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="apmi-card">
              <img src="assets/img/tools.svg" alt="">
              <h6 class="m-0">Well Equiped</h6>
              <p class="m-0">
                We ensure our handymen are equiped with the best tools and make sure that they are smartly uniformed.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="apmi-card">
              <i class="fas fa-business-time"></i>
              <h6 class="m-0">Wide Availability</h6>
              <p class="m-0">
                We provide services both on weekdays and weekends. We also provide a late night service for emergencies
                (at a higher rate).
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="hmj-pservice">
    <div class="container">
      <div class="hmjp-header d-flex justify-content-between">
        <h5 class="m-0">Popular on-demand handyman services.</h5>
        <button type="button" class="btn btn-primary" [routerLink]="['/services']">View all</button>
      </div>
      <div class="row row-eq-height">
        <div class="col-lg-3 col-md-4 hmjp-smain" *ngFor="let service of popularCategories"
          (click)="openBookingPop(service.title, service.category)">
          <div class="hmjps-card use-pointer">
            <img src="../../../assets/img/services/{{service.img}}.jpg" alt="electrical_service.jpg">
            <div class="hmjps-cinner">
              <h6 class="m-0 text-capitalize">{{service.title}}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="apply-sub" id="appimg">
    <div class="container-fluid">
      <div class="row m-0">
        <div class="col-lg-6 col-md-6">
          <div class="apsub-left">
            <h5 class="m-0">ALL KINDS OF BENEFITS</h5>
            <p class="m-0">
              We understand that when it comes to property repairs and improvements, you are interested in two main
              things:
              time and quality of work. That is why, no matter how big or small your project is, our skilled handymen & women
              will
              aim to make the whole ordeal as efficiently as possible, without compromising their workmanship. Also,
              they are:
            </p>
            <ul class="list-group">
              <li class="list-group-item"><img src="assets/img/brainstorm.svg" alt="" width="25px"> Multi Skilled</li>
              <li class="list-group-item"><i class="fas fa-brain text-primary"></i> Knowledgeable Specialists</li>
              <li class="list-group-item"><i class="fas fa-business-time text-primary"></i> Available on weekends and
                official holidays</li>
              <li class="list-group-item"><i class="fas fa-user-shield text-primary"></i> Clear pricing policy, no
                hidden “surprise” charges</li>
              <li class="list-group-item"><i class="fas fa-calendar-alt text-primary"></i> No fees for
                cancellations/reschedule</li>
              <li class="list-group-item"><i class="fas fa-clock text-primary"></i> Specific arrival time of your
                handyman</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 p-0">
          <div class="apsub-right">
            <img src="../../../assets/img/apart1.jpg" alt="" [style.height]="mainHeight">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="apply-sub apslast mb-5">
    <div class="container-fluid">
      <div class="row m-0">
        <div class="col-lg-4 col-md-6 p-0">
          <div class="apsub-right text-right">
            <img src="../../../assets/img/phone.png" alt="" class="apr-img">
          </div>
        </div>
        <div class="col-lg-8 col-md-6">
          <div class="apsub-left aps-download">
            <div class="apsd-main">
              <h5 class="m-0">Get the EjumaHub App</h5>
              <p class="m-0 d-none d-sm-block">
                Book and manage appointments, manage your profile, <br> rate your pro handymen and so much more.
              </p>
              <p class="m-0 d-block d-sm-none ">
                Book and manage appointments, manage your profile, rate your pro handymen and so much more.
              </p>
              <div class="d-flex justify-content-start apply-simg">
                <img src="../../../assets/img/psbadge.svg" class="use-pointer" alt="" (click)="launchStore('playstore')">
                <img src="../../../assets/img/asbadge.svg" class="use-pointer" alt="" (click)="launchStore('appstore')">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade mainBookModal" id="bookModal" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Book Details</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="bookmodal">
            <div class="bookinfo">
              <h5 class="m-0 text-capitalize">{{selectedService}}</h5>
            </div>
            <div class="form-group">
              <label for="bookdate">Select date for the service</label>
              <input type="date" min="{{todaysDate| date:'yyyy-MM-dd'}}" max="{{maxDate| date:'yyyy-MM-dd'}}"
                class="form-control" [(ngModel)]="bookdate" name="bookdate" id="bookdate">
            </div>
            <div class="form-group">
              <label for="booktime">Prefer time for the serivce</label>
              <input type="time" class="form-control" [(ngModel)]="booktime" name="booktime" id="booktime">
            </div>
            <div class="form-group">
              <label for="booktime">Additional Information</label>
              <textarea name="bookdesc" id="bookdesc" class="form-control" [(ngModel)]="bookdesc" rows="5"
                placeholder="Type the description..."></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="addBookCart('cart')">Add to Booking Cart</button>
          <button type="button" class="btn btn-dark" (click)="addBookCart('book')">Book Now</button>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</section>