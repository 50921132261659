<section class="bookings-main">
  <app-header></app-header>
  <div class="books-main">
    <div class="container">
      <app-loader></app-loader>
      <div class="row">
        <div class="col-lg-9">
          <div class="books-top">
            <div class="d-flex justify-content-between">
              <h4 class="m-0">Your Bookings</h4>
              <button type="button" class="btn btn-primary" [routerLink]="['/services']">Add Booking</button>
            </div>
          </div>
          <div class="books-bot">
            <div class="empytState" *ngIf="allBookings.length === 0 && emptyState === true">
              <div class="empty-card text-center">
                <img src="../../../assets/img/list.svg" alt="">
                <h5 class="m-0">
                  Your bookings are empty
                </h5>
              </div>
            </div>
            <div class="row" *ngIf="allBookings.length === 0 && emptyState === null">
              <div class="col-lg-4 col-md-6" *ngFor="let address of skeletonList">
                <div class="booking-card">
                  <ngx-skeleton-loader
                    [theme]="{width: '100%','border-radius': '50px',height: '10px','margin-bottom': '5px'}">
                  </ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    [theme]="{width: '80%','border-radius': '50px',height: '10px','margin-bottom': '5px'}">
                  </ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    [theme]="{width: '60%','border-radius': '50px',height: '10px','margin-bottom': '5px'}">
                  </ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    [theme]="{width: '50%','border-radius': '50px',height: '10px','margin-bottom': '5px'}">
                  </ngx-skeleton-loader>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-6" *ngFor="let book of allBookings">
                <div class="booking-card use-pointer" data-toggle="modal" data-backdrop="static"
                  data-target="#bookModal" (click)="selectBooking(book)">
                  <div class="d-flex justify-content-between mb-1">
                    <span class="text-uppercase">#{{book.bookId}}</span>
                  </div>
                  <div *ngIf="book.service.length === 1">
                    <div class="mb-1">
                      <span class="badge text-capitalize" [ngClass]="{'badge-success': book.service[0].info.status === 'completed','badge-assigned': book.service[0].info.status === 'assigned', 'badge-warning': book.service[0].info.status ===
                    'pending', 'badge-danger': book.service[0].info.status ===
                    'cancelled'}">{{book.service[0].info.status}}</span>
                    </div>
                    <h5 class="m-0 text-capitalize">{{book.service[0].service}}</h5>
                    <h6 class="m-0 text-capitalize">{{book.service[0].category}}</h6>
                    <p class="m-0">
                      {{book.service[0].desc}}
                    </p>
                  </div>
                  <div *ngIf="book.service.length > 1">
                    <div class="mb-1">
                      <span class="badge badge-dark">Check booking status</span>
                    </div>
                    <h5 class="m-0 text-capitalize">Multiple Service Booking</h5>
                    <h6 class="m-0 text-capitalize">{{book.service.length}} handyman services booked</h6>
                    <!-- <p class="m-0">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat esse minus.
                    </p> -->
                  </div>
                  <div class="text-right">
                    <span class="text-capitalize"><i class="fas fa-calendar-check"></i>
                      {{book.createdAt * 1000 | date: 'mediumDate'}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <app-side-promo></app-side-promo>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</section>

<div class="modal fade" id="bookModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{selectedBooking?.bookId}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="book-dm-main">
          <div class="bsm-card use-pointer" *ngFor="let service of selectedBooking?.service; let id = index">
            <h6 class="m-0 text-capitalize">{{service?.service}}</h6>
            <span class="text-capitalize">{{service?.category}}</span>
            <p class="m-0" *ngIf="service?.desc !== ''">
              {{service?.desc}}
            </p>
            <div class="d-flex justify-content-between">
              <span class="badge text-capitalize"
                [ngClass]="{'badge-warning':service?.info.status === 'pending', 'badge-assigned':service?.info.status === 'assigned', 'badge-success':service?.info.status === 'completed', 'badge-danger':service?.info.status === 'cancelled' }">{{service?.info.status}}</span>
              <span>{{service?.date | date: 'mediumDate'}}, {{service?.time | date: 'shortTime'}}</span>
            </div>
            <div class="bsm-cinner" *ngIf="mainWorkers[id]?.state === 'empty'">
              <h6>Pro not assigned, please wait!</h6>
            </div>
            <div class="bsm-cinner d-flex justify-content-start" *ngIf="mainWorkers[id]?.state !== 'empty'">
              <div>
                <img [src]="mainWorkers[id]?.photo_url" alt="profile-img" *ngIf="mainWorkers[id]?.photo_url !== ''">
                <img src="../../../assets/img/profile.png" alt="profile-img" *ngIf="mainWorkers[id]?.photo_url === ''">
              </div>
              <div class="bsm-desc">
                <div class="d-flex justify-content-between align-item-center">
                  <div>
                    <h6 class="m-0 text-capitalize">{{mainWorkers[id]?.first_name}} {{mainWorkers[id]?.last_name}}</h6>
                    <button type="button" class="btn btn-primary btn-sm btn-vpr" data-toggle="modal"
                      (click)="selectWorker(mainWorkers[id], id)" data-target="#bookWorkerModal">View profile</button>
                  </div>
                  <div>

                    <h6 class="m-0">Fee: GHc {{service?.info.fee}}
                      <span class="badge badge-success text-capitalize" *ngIf="service?.info.paid === true">Paid</span>
                    </h6>
                    <button type="button" class="btn btn-primary" (click)="openPay(service?.info.fee, id)"
                      *ngIf="service?.info.paid === false">
                      <i class="fas fa-money-bill"></i>
                      Pay
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bsm-address">
            <div class="bsma-header">
              <h5 class="m-0">
                Booking Address
              </h5>
            </div>
            <div>
              <h6 class="m-0 text-capitalize">{{selectedBooking?.address.street}}</h6>
              <p class="m-0">{{selectedBooking?.address.city}}</p>
              <p class="m-0">{{selectedBooking?.address.country}}</p>
              <p class="m-0">{{selectedBooking?.address.phonenum}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="bookWorkerModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Worker Profile</h5>
        <button type="button" id="workm-close" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body rev-mod-body">
        <div class="book-dm-main">
          <div class="worker-card">
            <div class="workc-top text-center">
              <div class="workc-bgImg">
                <div>
                  <img [src]="selectedWorker?.photo_url" alt="profile-img" *ngIf="selectedWorker?.photo_url !== ''">
                  <img src="../../../assets/img/profile.png" alt="profile-img" *ngIf="selectedWorker?.photo_url === ''">
                </div>

              </div>
              <h5 class="m-0">{{selectedWorker?.first_name}} {{selectedWorker?.last_name}}</h5>
              <p class="m-0 mb-2"><span>ID: {{selectedWorker?.workerID}}</span></p>
              <button type="button" class="btn btn-primary" (click)="callNumber()">
                <i class="fas fa-phone"></i> Call
              </button>
            </div>
            <div class="row wk-bot">
              <div class="col-lg-6 col-md-6 text-center">
                <span>Jobs</span>
                <h6 class="m-0">{{selectedWorker?.assignedJobs}}</h6>
              </div>
              <div class="col-lg-6 col-md-6 text-center">
                <span>Rating</span>
                <h6 class="m-0 d-flex justify-content-center align-item-center wkr-span">
                  <span>{{averageRating | number: '1.0-1'}}</span> <i class="fas fa-star primary"></i>
                </h6>
              </div>
              <div class="col-lg-12 text-center">
                <span>Primary Service</span>
                <h6 class="m-0">{{selectedWorker?.primary_service}}</h6>
              </div>
            </div>

            <div class="workc-review">
              <h5 class="m-0">Rate Your Pro</h5>
              <div class="text-center mb-3">
                <star-rating [starType]="'svg'" (ratingChange)="onRatingChange($event)"></star-rating>
              </div>
              <div class="form-group">
                <textarea class="form-control" [(ngModel)]="review" name="review" id="review"
                  placeholder="Write review here..." rows="3"></textarea>
              </div>
              <button type="button" (click)="submitReview()" class="btn btn-primary btn-block">Submit</button>
            </div>
            <div class="workc-bot mt-2">
              <h5 class="m-0">Reviews</h5>
              <div class="workcb-reall">
                <div class="workcr-card" *ngFor="let review of reviewData">
                  <star-rating [rating]="review.rating" [starType]="'svg'"></star-rating>
                  <h6 class="m-0">{{review.review}}</h6>
                  <span>{{review.createdAt * 1000 | date: 'medium'}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>