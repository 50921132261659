import { FuncService } from './../../services/func.service';
import { UiService } from './../../services/ui.service';
import { Component, OnInit } from '@angular/core';
import { format, parseISO } from 'date-fns';
declare var $;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public popularCategories = [];
  public todaysDate;
  public maxDate;
  public serviceCategories = [];
  public activeCategory;
  public mainHeight;
  public selectedService = '';
  public selectedCategory = '';
  public bookdate = '';
  public booktime = '';
  public bookdesc;
  public activeIndex;
  constructor(private uiService: UiService, private funcService: FuncService) { }

  ngOnInit() {
    this.getAllServices();
    this.getALlServiceCategories();
    setTimeout(() => {
      this.getDivHeight();
      this.assignDates();
    }, 500);
  }

  public goToLogin() {
    this.uiService.goToRoute('/login');
  }

  public getAllServices() {
    this.funcService.getPopularServices().subscribe((data) => {
      this.popularCategories = data['popular'];
    });
  }

  public launchStore(type) {
    this.uiService.goToStore(type);
  }

  public getALlServiceCategories() {
    this.funcService.getService().subscribe((data) => {
      this.serviceCategories = data['resources'];
    });
  }

  public goToCategory() {
    const data = [this.activeCategory, this.activeIndex]
    this.uiService.changeCategoryState(data);
    this.uiService.goToRoute('services');
  }

  public openBookingPop(service, category) {
    $('#bookModal').modal({ backdrop: 'static', keyboard: false });
    this.selectedService = service;
    this.selectedCategory = category;
  }

  public changeOption(ev) {
    if (ev.target.value !== 'What service do you need?') {
      const aCat = this.serviceCategories.find(cat => cat.title = ev.target.value);
      this.activeCategory = aCat;
      this.activeIndex = ev.target.selectedIndex;
    }
  }
  public addBookCart(type) {
    const bookData = {
      service: this.selectedService,
      date: this.bookdate,
      desc: this.bookdesc,
      time: this.booktime,
      category: this.selectedCategory
    };
    if (this.bookdate !== '' && this.booktime !== '') {
      this.funcService.addToBookCart(bookData);
      this.closeModal();
      if (type === 'book') {
        this.uiService.goToRoute('service-booking');
      }
    } else if (this.bookdate === '') {
      this.uiService.showWarning('Please add booking date.')
    } else if (this.booktime === '') {
      this.uiService.showWarning('Please add booking time.')
    }
  }

  public closeModal() {
    this.selectedService = '';
    this.bookdate = '';
    this.bookdesc = '';
    this.booktime = '';
  }

  public getDivHeight() {
    const divHeight = document.getElementById('appimg').offsetHeight;
    const mHeight = `${divHeight}px`;
    this.mainHeight = mHeight;
  }

  public formatDate(value: string) {
    return format(parseISO(value), 'MMM dd yyyy');
  }
  public assignDates() {
    const todayNow = new Date(this.funcService.createdAt * 1000).toISOString();
      this.todaysDate = todayNow;
      const date = new Date(todayNow);
      date.setFullYear(date.getFullYear() + 2);
      const formatDate = new Date(date).toISOString();
      const newDate = this.formatDate(formatDate);
      this.maxDate = newDate;
  }
}
