<section class="serlist-main">
  <app-header></app-header>
  <div class="hmj-search">
    <div class="hmjs-inner">
      <h4 class="m-0 ">
        Select the required Service and a Pro Help is only a Booking Away!
      </h4>
      <div class="selj-search text-center">
        <div class="row">
          <div class="col-lg-8 p-0">
            <select class="custom-select" [(ngModel)]="seCategory" name="Category" required placeholder="d.ff">
              <option hidden value="" disabled selected>What services do you need?</option>
              <option [value]="category?.title" *ngFor="let category of serviceCategories; let id = index">
                {{category?.title}}</option>
            </select>
          </div>
          <div class="col-lg-4 p-0">
            <button type="button" class="btn btn-primary border-0 btn-block" (click)="goToCategory()">Book</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="serlist-bot">
    <div class="container">

      <div class="ser-catmain">
        <div class="owl-carousel owl-theme">
          <div class="sercat-card item text-center use-pointer" (click)="openBookingPop('Electrical Service', 'Electrical')">
            <img src="../../../assets/img/services/electrical_service.jpg" alt="electrical_service.jpg">
            <h6 class="m-0 text-capitalize">Electrical Service</h6>
          </div>
          <div class="sercat-card item text-center use-pointer" (click)="openBookingPop('Masonry Service', 'Masonry Projects')">
            <img src="../../../assets/img/services/masonry.jpg" alt="masonry.jpg">
            <h6 class="m-0 text-capitalize">Masonry Service</h6>
          </div>
          <div class="sercat-card item text-center use-pointer" (click)="openBookingPop('Plumbing Service', 'Plumbing')">
            <img src="../../../assets/img/services/plumbing-service.jpg" alt="plumbing-service.jpg">
            <h6 class="m-0 text-capitalize">Plumbing Service</h6>
          </div>
          <div class="sercat-card item text-center use-pointer" (click)="openBookingPop('General Auto Services', 'Auto Mechanic')">
            <img src="../../../assets/img/services/general-auto-service.jpg" alt="general-auto-service.jpg">
            <h6 class="m-0 text-capitalize">General Auto Services</h6>
          </div>
          <div class="sercat-card item text-center use-pointer" (click)="openBookingPop('House Painting', 'Painting')">
            <img src="../../../assets/img/services/house-painting.jpg" alt="house-painting.jpg">
            <h6 class="m-0 text-capitalize">House Painting</h6>
          </div>
          <div class="sercat-card item text-center use-pointer"
            (click)="openBookingPop('Outdoor Furniture Assembly', 'Furniture & Assembly')">
            <img src="../../../assets/img/services/outdoor-furniture.jpg" alt="outdoor-furniture.jpg">
            <h6 class="m-0 text-capitalize">Outdoor Furniture Assembly</h6>
          </div>
          <div class="sercat-card item text-center use-pointer" (click)="openBookingPop('Home Cleaning', 'Cleaning')">
            <img src="../../../assets/img/services/home-cleaning.jpg" alt="home-cleaning.jpg">
            <h6 class="m-0 text-capitalize">Home Cleaning</h6>
          </div>
          <div class="sercat-card item text-center use-pointer"
            (click)="openBookingPop('Smart Security Cam Installation', 'Smart Home')">
            <img src="../../../assets/img/services/security-cam.jpg" alt="security-cam.jpg">
            <h6 class="m-0 text-capitalize">Smart Security Cam Installation</h6>
          </div>
          <div class="sercat-card item text-center use-pointer"
            (click)="openBookingPop('Air Conditioner Installation', 'Air Conditioner')">
            <img src="../../../assets/img/services/aircon-install.jpg" alt="aircon-install.jpg">
            <h6 class="m-0 text-capitalize">Air Conditioner Installation</h6>
          </div>
          <div class="sercat-card item text-center use-pointer" (click)="openBookingPop('Lawn Mowing', 'Gardening Service')">
            <img src="../../../assets/img/services/mowing.jpg" alt="mowing.jpg">
            <h6 class="m-0 text-capitalize">Lawn Mowing</h6>
          </div>
          <div class="sercat-card item text-center use-pointer" (click)="openBookingPop('Food Services', 'Entertainment')">
            <img src="../../../assets/img/services/food.jpg" alt="food.jpg">
            <h6 class="m-0 text-capitalize">Food Services</h6>
          </div>
          <div class="sercat-card item text-center use-pointer" (click)="openBookingPop('Metal Welding', 'Welding & Fabrication')">
            <img src="../../../assets/img/services/metal-welding.jpg" alt="metal-welding.jpg">
            <h6 class="m-0 text-capitalize">Metal Welding</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="sercl-main" id="serclmid">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-4 se-lm">
          <div class="sercl-inner">
            <div class="sercl-calist">
              <div class="input-group">
                <input type="text" class="form-control" [(ngModel)]="searchText" (keyup)="searchingData($event)" placeholder="Search service..." aria-label="Search service..." aria-describedby="searchservice">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="searchservice">
                    <i class="fas fa-search"></i>
                  </span>
                </div>
              </div>
              <h5 class="m-0">Service Catetogies</h5>
              <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                <a class="nav-link text-capitalize pill-main" *ngFor="let category of serviceCategories, let id = index"
                  data-toggle="pill" href="#{{category.title}}" (click)="changeActiveTab(category, id)"
                  [ngClass]="{'active': activeTab === id}" role="tab" aria-selected="true">{{category.title}}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-9 col-md-8" id="serc-con" *ngIf="searchActivate === false" >
          <div data-spy="scroll" data-target="#v-pills-tab">
            <div class="tab-content" id="v-pills-tabContent">
              <div class="tab-pane fade show active" role="tabpanel" *ngFor="let acategory of serviceCategories"
                id="{{acategory.title}}">
                <!-- <div class="tab-pane fade" [ngClass]="{'active': activeTab === id, 'show': activeTab === id}" id="{{acategory.id}}" role="tabpanel" *ngFor="let acategory of serviceCategories, let id= index"> -->
                <div class="sercl-head">
                  <h6 class="m-0 text-capitalize">{{acategory.title}}</h6>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 sel-mr" *ngFor="let service of acategory.services">
                    <div class="hmjps-card use-pointer" (click)="openBookingPop(service.title, acategory.title)">
                      <img src="../../../assets/img/services/{{service.img}}.jpg" alt="" *ngIf="service.img !== ''">
                      <div class="hmjps-cinner">
                        <h6 class="m-0 text-capitalize">{{service.title}}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-9 col-md-8" id="serc-con" *ngIf="searchActivate === true" >
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 sel-mr" *ngFor="let seservice of searchServiceMain | filter: searchText">
              <div class="hmjps-card use-pointer" (click)="openBookingPop(seservice.service, seservice.category)">
                <img src="../../../assets/img/pattern.png" alt="" *ngIf="seservice.img === ''">
                <img src="../../../assets/img/services/{{seservice.img}}.jpg" alt="" *ngIf="seservice.img !== ''">
                <div class="hmjps-cinner">
                  <h6 class="m-0 text-capitalize">{{seservice.service}}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade mainBookModal" id="bookModal" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Book Details</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="bookmodal">
            <div class="bookinfo">
              <h5 class="m-0 text-capitalize">{{selectedService}}</h5>
            </div>
            <div class="form-group">
              <label for="bookdate">Select date for the service</label>
              <input type="date" min="{{todaysDate| date:'yyyy-MM-dd'}}" max="{{maxDate| date:'yyyy-MM-dd'}}" class="form-control" [(ngModel)]="bookdate" name="bookdate" id="bookdate">
            </div>
            <div class="form-group">
              <label for="booktime">Prefer time for the serivce</label>
              <input type="time" class="form-control" [(ngModel)]="booktime" name="booktime" id="booktime">
            </div>
            <div class="form-group">
              <label for="booktime">Additional Information</label>
              <textarea name="bookdesc" id="bookdesc" class="form-control" [(ngModel)]="bookdesc" rows="5"
                placeholder="Please describe the job in detail..."></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="addBookCart('cart')">Add to Booking Cart</button>
          <button type="button" class="btn btn-dark" (click)="addBookCart('book')">Book Now</button>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</section>