import { AuthService } from './services/auth.service';
import { FuncService } from './services/func.service';
import { Component, OnInit } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle';

declare var $;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public allCities = [];
  public city;
  public token = '';
  public disableBtn = false;
  constructor(private funcService: FuncService, private bnIdle: BnNgIdleService, private authService: AuthService) { }

  ngOnInit() {
    this.funcService.getAreas().subscribe((data: any) => {
      this.allCities = data.locations[0].states[0].cities;
    });
    this.bnIdle.startWatching(300).subscribe((res) => {
      if (res) {
        this.isIdle();
      }
    });

  }

  public isIdle = async () => {
    const userD = await localStorage.getItem('ejhUser');
    if (userD !== null) {
      this.authService.logoutUser();
    }
  }

  public openLocation = () => {
    this.funcService.isUserLocationSet();
  }

  public checkLocationSet = async () => {
    const userD = await localStorage.getItem('ejhUser');

  }
  // public closeLocModal() {
  //   $("#locationModal").attr("data-dismiss", "modal").click();
  // }

  public saveLocation() {
    this.disableBtn = true;
    this.funcService.updateUserLocation(this.city);
    setTimeout(() => {
      this.disableBtn = false;
      this.city = '';
    }, 2000);
  }

  public async verifyToken() {
    const userData = await this.funcService.getUserProfile();
    this.funcService.verifyTokenEmail(userData, this.token);
  }
}
