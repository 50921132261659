<div class="logintop">
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light">
      <a class="navbar-brand" [routerLink]="['/home']" routerLinkActive="active">
        <img src="../../../assets/img/logo-black.png" alt="" height="35px">
      </a>
      <button class="navbar-toggler d-lg-none" type="button" data-toggle="collapse" data-target="#collapsibleNavId"
        aria-controls="collapsibleNavId" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="collapsibleNavId">
        <div class="mr-auto mt-2 mt-lg-0" *ngIf="isRegularRoute == false">
          <div class="input-group head-search">
            <input type="text" class="form-control" placeholder="Search Events">
            <div class="input-group-append">
              <span class="input-group-text">
                <i class="fas fa-search"></i>
              </span>
            </div>
          </div>
        </div>
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0" *ngIf="isRegularRoute == true">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/home']" routerLinkActive="active">Home <span
                class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/services']" routerLinkActive="active"> All Services</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/apply']" routerLinkActive="active">Become a Pro</a>
          </li>
          <li class="nav-item">
            <button type="button" class="btn btn-primary" (click)="callNumber()">
              <i class="fas fa-phone"></i>
              0302984047
            </button>
            <!-- <a class="nav-link" [routerLink]="['/apply']" routerLinkActive="active">Become a Pro</a> -->
          </li>
        </ul>
        <div class="my-2 my-lg-0">
          <div class="d-flex justify-content-end hc-main">
            <div class="head-count">
              <a [routerLink]="['/service-booking/services']">
                <i class="fas fa-briefcase"></i>
              </a>
              <span class="badge badge-primary">{{cartCount}}</span>
            </div>
            <button class="btn btn-primary hm-loginbtn my-2 my-sm-0" *ngIf="isLoggedIn === null"
              [routerLink]="['/login']">
              <i class="fas fa-sign-in-alt"></i>
              Login
            </button>
            <div class="dropdown" *ngIf="isLoggedIn === 'true'">
              <button class="btn dropdown-toggle" type="button" id="profileId" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <img src="{{userData.photo_url}}" alt="" *ngIf="userData.photo_url !== ''" >
                <img src="../../../assets/img/profile.png" alt="" *ngIf="userData.photo_url === ''" >
              </button>
              <div class="dropdown-menu" aria-labelledby="profileId">
                <a class="dropdown-item" [routerLink]="['/bookings']">Bookings</a>
                <a class="dropdown-item" [routerLink]="['/address-list']">Address List</a>
                <a class="dropdown-item" [routerLink]="['/account-settings']">Account</a>
                <a class="dropdown-item" (click)="logoutMainUser()" >Logout</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>
