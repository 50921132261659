import { AuthService } from './../../services/auth.service';
import { UiService } from './../../services/ui.service';
import { FuncService } from './../../services/func.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit {
  public allBookings: any = [];
  public selectedWorker;
  public skeletonList = [1, 2, 3, 4, 5, 6];
  public emptyState = null;
  public selectedBooking;
  public workerKeys;
  public reviewData = [];
  public mainWorkers = [];
  public userData;
  public review = '';
  public workerIndex;
  public averageRating = 0;
  public ratingNum = 0;
  constructor(private funcService: FuncService, private uiService: UiService, public auth: AuthService) { }

  ngOnInit() {
    this.uiService.payState.subscribe(payInfo => {
      if (payInfo !== null) {
        this.bookingUpdate();
      }
    });
    this.getUserProfile();
    this.getAllUserBookings();
  }

  public getAllUserBookings() {
    this.funcService.getBookings().then((data) => {
      if (data.empty === true) {
        this.emptyState = true;
        this.uiService.hideLoader();
      } else {
        data.docs.map(res => {
          this.allBookings.push(res.data());
          this.emptyState = false;
        });
        this.uiService.hideLoader();
      }
    });
  }

  public selectBooking(data) {
    this.selectedBooking = data;
    setTimeout(() => {
      this.loadWorkers();
    }, 1000);
  }

  public selectWorker(data, id) {
    this.reviewData = [];
    this.workerIndex = id;
    this.averageRating = 0;
    this.selectedWorker = data;
    this.averageRating = data.totalRating / data.personsRated;
    this.getWorkerReviews();
  }

  public getAllWorkers(keys) {
    const mainWorkers = [];
    keys.map((data) => {
      this.funcService.getWorker(data).then((res) => {
        if (res.empty === true) {
          mainWorkers.push({ state: 'empty' });
        } else {
          res.docs.map((mres) => {
            mainWorkers.push(mres.data());
          });
        }
        this.mainWorkers = mainWorkers;
      });
    });
  }

  public loadWorkers() {
    const workerKey = [];
    const serviceData = this.selectedBooking.service;
    serviceData.map((res, index) => {
      this.mainWorkers.push({ state: 'empty' });
      workerKey.push(res.info.worker);
      this.workerKeys = workerKey;
      setTimeout(() => {
        this.getAllWorkers(workerKey);
      }, 500);
    });
  }

  public async getUserProfile() {
    const userD = await localStorage.getItem('ejhUser');
    const userParseData = JSON.parse(userD);
    this.userData = userParseData;
  }

  public openPay(totalPrice, id) {
    const mainCharge = (totalPrice * 101.9) / 100;
    this.funcService.payWithPaystack(this.userData.email, mainCharge, this.selectedBooking.bookId, this.selectedBooking.bookKey, id);
  }

  public closeModal() {
    const btnElem = document.getElementById('workm-close');
    btnElem.click();
  }

  public async submitReview() {
    if (this.ratingNum !== 0) {
      this.funcService.rateReviewWorker(this.ratingNum, this.review, this.selectedWorker.workerKey, this.selectedBooking.bookKey, this.userData.ejuUAKey, this.selectedWorker, this.workerIndex, this.selectedBooking)
      this.ratingNum = 0;
      this.review = '';
      this.selectedWorker.totalRating += this.ratingNum;
      this.selectedWorker.personRated += 1;
      this.selectedBooking.service[this.workerIndex].info.review = this.review;
      this.closeModal();
    } else {
      this.uiService.showWarning('Please rate and write a short review.')
    }
  }

  public onRatingChange(ev) {
    this.ratingNum = ev.rating;
  }

  public bookingUpdate() {
    this.uiService.showLoader();
    this.funcService.getBookingSingle(this.selectedBooking.bookKey).then((res) => {
      this.selectedBooking = res.data();
      this.mainWorkers = []
      this.workerKeys = []
      this.loadWorkers();
    });
    setTimeout(() => {
      this.uiService.hideLoader();
    }, 1000);
  }

  public callNumber() {
    const ahref = document.createElement('a');
    ahref.href = `tel:${this.selectedWorker.phone_one}`;
    ahref.click();
  }

  public getWorkerReviews() {
    this.funcService.getWorkerReview(this.selectedWorker.workerKey).then((data => {
      if (data.empty === true) {
        this.emptyState = true
      } else {
        this.emptyState = false;
        data.docs.map(res => {
          this.reviewData.push(res.data())
        });
      }
    }))
  }
}
