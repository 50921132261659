<section class="loginmain">
  <app-header></app-header>
  <div class="loginbot">
    <div class="container">
      <app-loader></app-loader>
      <div class="loginb-main">
        <div class="lbm-header text-center">
          <h5 class="m-0">Welcome Back...</h5>
          <p class="m-0">Sign in with email & password.</p>
        </div>
        <div class="lbm-form">
          <form [formGroup]="loginForm">
            <div class="form-group">
              <input formControlName="email" type="email" class="form-control" name="email" id="email" placeholder="Email">
            </div>
            <div class="form-group">
              <input formControlName="password" type="password" class="form-control" name="password" id="password"
                placeholder="Password">
            </div>
            <div class="form-group">
              <button type="button" [disabled]="!loginForm.valid" (click)="loginUserWithEmail()"
                class="btn btn-primary btn-block">Sign In</button>
            </div>
          </form>
        </div>
        <div class="lbm-options text-center">
          <a [routerLink]="['/reset-password']">Forgot Password?</a>
          <button type="button" class="btn btn-gmail btn-block mt-2" (click)="loginUserGoogle()">
            <i class="fab fa-google-plus"></i> Connect with Google
          </button>
          <button type="button" class="btn btn-light btn-block" (click)="goToRegister()">
            Sign Up
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</section>
