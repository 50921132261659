import { FuncService } from './../../services/func.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  public contactForm: UntypedFormGroup;

  constructor(private funcService: FuncService) { }

  ngOnInit() {
    this.contactForm = new UntypedFormGroup({
      email: new UntypedFormControl('', Validators.compose([
        Validators.required, Validators.email
      ])),
      name: new UntypedFormControl('', Validators.compose([
        Validators.required
      ])),
      title: new UntypedFormControl('', Validators.compose([
        Validators.required
      ])),
      message: new UntypedFormControl('', Validators.compose([
        Validators.required
      ])),
    });
  }

  public sendMessage() {
    this.funcService.sendContactMessage(this.contactForm.value);
  }

}
