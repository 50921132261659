<section class="about-main">
  <app-header></app-header>
  <div class="jumb-bg">
    <div class="jumb-bginner">
      <div class="container">
        <h4 class="m-0">
          About Us
        </h4>
      </div>
    </div>
  </div>
  <div class="abm-bgtext">
    <div class="container">
      <div class="abmb-company">
        <div class="row">
          <div class="col-lg-8">
            <div class="abmb-cleft abmb-clm">
              <div class="abmb-clinner abt-text">
                <h5 class="m-0">About EjumaHub</h5>
                <p class="m-0">
                  EjumaHub is a platform that connects independent handyman service professionals with customers. 
                  We deliver professional, reliable maintenance, repairs, and improvements for residential and commercial 
                  properties promptly and affordably. Our team of top-rated, pre-vetted independent service professionals 
                  are available to help households and businesses deal with all those cleaning, electrical, plumbing, painting, 
                  and other jobs ranging in size and scope and that require urgent attention.
                </p>
              </div>
            </div>
            <div class="abmb-cleft">
              <div class="abmb-clinner abt-text">
                <h5 class="m-0">Our Story</h5>
                <p class="m-0">
                  The inspiration behind EjumaHub was the difficulties one would face of finding a professional handyman or technician. 
                  One would have to find these handymen through word of mouth or from a hardware store nearby. The obvious problem with 
                  this was that most of these handymen lacked professionalism, and / or skills. Furthermore they were often just not available 
                  or reliable, or simply charged way over what is considered reasonable. It was clear that there was a need for a professional 
                  handyman service and we jumped at the idea!
                  <br/> <br/>
                  EjumaHub is owned by the Appha Labs Limited, which is proudly a 100% Ghanaian-owned company. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</section>
