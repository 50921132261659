<section class="contact-main">
  <app-header></app-header>
  <div class="apply-main">
    <div class="apply-form">
      <div class="container">
        <app-loader></app-loader>
        <div class="row">
          <div class="col-lg-7 col-md-6">
            <div class="apm-bgheader">
              <h4 class="m-0">
                Start earning money right away!
              </h4>
              <p class="m-0 pr-5">Gain acess to hundreds of jobs in your city and get paid soon after a job is completed. </p>
              <button type="button" class="btn btn-md btn-black" (click)="sendProRequest($event)">Apply Now</button>
            </div>
          </div>
        </div>
      </div>

      <div class="apply-over d-flex justify-content-end apo-bc">
        <img src="assets/img/workerpic.png" alt="">
      </div>
    </div>
    <div class="container">
      <div class="apply-info">
        <div class="apmi-header">
          <h5 class="m-0">BENEFITS TO OUR PROS!</h5>
          <p>
            There are many benefits professional handymen get from working with us at EjumaHub, including but not limited to
          </p>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-4">
            <div class="apmi-card">
              <i class="fas fa-money-bill"></i>
              <h6 class="m-0">GREAT PAY</h6>
              <p class="m-0">
                No more squabbling with customers about how much you should get paid. You know how much you're being paid before you take it.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="apmi-card">
              <i class="fas fa-calendar-alt"></i>
              <h6 class="m-0">FLEXIBLE SCHEDULES</h6>
              <p class="m-0">
                Build a full schedule of when you're available to work and we will connect customers to you at your convenience.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4">
            <div class="apmi-card">
              <i class="fas fa-money-check-alt"></i>
              <h6 class="m-0">EASY PAYMENTS</h6>
              <p class="m-0">
                Your payments are deposited into your MoMo or bank account soon after the job is completed.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="apply-sub">
      <div class="container-fluid">
        <div class="row m-0">
          <div class="col-lg-6 col-md-6">
            <div class="apsub-left">
              <h5 class="m-0">Requirements</h5>
              <ul class="list-group">
                <li class="list-group-item"><i class="fas fa-briefcase text-primary"></i> Must have paid experience
                  with the services you are applying for</li>
                <li class="list-group-item"><i class="fas fa-handshake text-primary"></i> Must have excellent customer
                  service skills</li>
                <li class="list-group-item"><i class="fas fa-users text-primary"></i> Team Player, Strong
                  Collaboration Skills</li>
              </ul>
              <p class="m-0">
                NB: EjumaHub is not an employer, but simply a platform that connects independent skilled professionals
                with customers.
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 p-0">
            <div class="apsub-right">
              <img src="../../../assets/img/slide3.jpg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</section>
