<section class="bookings-main">
  <app-header></app-header>
  <div class="books-main">
    <div class="container">
      <app-loader></app-loader>
      <div class="row">
        <div class="col-lg-9 col-md-12 bks-main">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <div class="accset-card">
                <div class="accheader">
                  <h5 class="m-0">Edit Profile</h5>
                </div>
                <div class="accs-top text-center">
                  <img src="{{profileData?.photo_url}}" alt="" *ngIf="profileData?.photo_url !== ''">
                  <img src="../../../assets/img/profile.png" alt="" *ngIf="profileData?.photo_url === ''">
                  <div class="mt-2">
                    <input type="file" id="file1" hidden #fileSelected (change)="changeImg($event)">
                    <button type="button" class="btn btn-primary" (click)="fileSelected.click()">Change Profile
                      Image</button>
                  </div>
                </div>
                <div class="accs-bot">
                  <form>
                    <div class="form-group">
                      <label for="fname">First name</label>
                      <input [(ngModel)]="profileData.first_name" type="text" class="form-control" name="fname"
                        id="fname" placeholder="Enter your first name">
                    </div>
                    <div class="form-group">
                      <label for="lname">Last name</label>
                      <input [(ngModel)]="profileData.last_name" type="text" class="form-control" name="lname"
                        id="lname" placeholder="Enter your last name">
                    </div>
                    <div class="form-group">
                      <label for="city">City</label>
                      <select [(ngModel)]="profileData.location" type="text" name="city" id="city" class="form-control">
                        <option *ngFor="let city of allCities" [value]="city.city">{{city.city}}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="email">Email</label>
                      <input [(ngModel)]="profileData.email" type="email" class="form-control" name="email" id="email"
                        readonly>
                    </div>
                    <button type="button" class="btn btn-primary" (click)="saveEditedProfile()">Save</button>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6">
              <div class="accset-card">
                <div class="accheader">
                  <h5 class="m-0">Reset Password</h5>
                </div>
                <div class="accset-bot ">
                  <div class="alert alert-warning" role="alert">
                    A password reset link will sent to your email address. Click on the link and follow the steps to
                    reset your password.
                  </div>
                  <button type="button" class="btn btn-primary" (click)="requestPassword()">Request password
                    reset</button>
                </div>
              </div>
              <div class="accset-card" *ngIf="profileData?.verified === false" >
                <div class="accheader">
                  <h5 class="m-0">Verify Email</h5>
                </div>
                <div class="accset-bot ">
                  <form>
                    <div class="form-group">
                      <label for="token">Enter the token</label>
                      <input [(ngModel)]="token" type="text" class="form-control" name="token" id="token">
                    </div>
                  </form>
                  <div class="d-flex justify-content-center align-items-center">
                    <button type="button" class="btn btn-secondary mr-2" (click)="requestToken()">Request token</button>
                    <button type="button" class="btn btn-primary" (click)="verifyToken()">Verify</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-12">
          <app-side-promo></app-side-promo>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</section>