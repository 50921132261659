import { LoggedInGuard } from './services/logged-in.guard';
import { AuthGuard } from './services/auth.guard';
import { ServiceCategoryComponent } from './main/service-category/service-category.component';
import { ServiceBookingComponent } from './main/service-booking/service-booking.component';
import { ProProfileComponent } from './main/pro-profile/pro-profile.component';
import { AddressListComponent } from './main/address-list/address-list.component';
import { UsersettingsComponent } from './main/usersettings/usersettings.component';
import { ApplyComponent } from './main/apply/apply.component';
import { BookingsComponent } from './main/bookings/bookings.component';
import { TermsPrivacyComponent } from './main/terms-privacy/terms-privacy.component';
import { AboutusComponent } from './default/aboutus/aboutus.component';
import { ForgotpassComponent } from './default/forgotpass/forgotpass.component';
import { SignupComponent } from './default/signup/signup.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './default/home/home.component';
import { ContactComponent } from './default/contact/contact.component';
import { LoginComponent } from './default/login/login.component';
import { ServiceListComponent } from './main/service-list/service-list.component';
import {
  redirectUnauthorizedTo,
  redirectLoggedInTo,
  canActivate,
} from '@angular/fire/auth-guard';

const redirectLoggedInToHome = () => redirectLoggedInTo(['/home']);

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'contactus', component: ContactComponent },
  { path: 'aboutus', component: AboutusComponent },
  { path: 'services', component: ServiceListComponent },
  { path: 'services/:id', component: ServiceListComponent },
  { path: 'login', component: LoginComponent, ...canActivate(redirectLoggedInToHome) },
  { path: 'register', component: SignupComponent, ...canActivate(redirectLoggedInToHome) },
  { path: 'bookings', component: BookingsComponent, canActivate: [AuthGuard] },
  { path: 'worker-profile', component: ProProfileComponent, canActivate: [AuthGuard] },
  { path: 'service-booking/:id', component: ServiceBookingComponent },
  // { path: 'service-category', component: ServiceCategoryComponent },
  { path: 'account-settings', component: UsersettingsComponent, canActivate: [AuthGuard] },
  { path: 'address-list', component: AddressListComponent, canActivate: [AuthGuard] },
  { path: 'apply', component: ApplyComponent },
  { path: 'terms/:id', component: TermsPrivacyComponent },
  { path: 'reset-password', component: ForgotpassComponent, ...canActivate(redirectLoggedInToHome) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
