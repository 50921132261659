import { AuthService } from './../../services/auth.service';
import { UiService } from './../../services/ui.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: UntypedFormGroup;
  constructor(private uiservice: UiService, private authService: AuthService) { }

  ngOnInit() {
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl('', Validators.compose([
        Validators.required, Validators.email
      ])),
      password: new UntypedFormControl('', Validators.required)
    });
  }

  public goToRegister() {
    this.uiservice.goToRoute('register')
  }

  public loginUserWithEmail() {
    this.authService.loginUserEmail(this.loginForm.value);
  }

  public loginUserGoogle() {
    this.authService.googleSignin();
  }

}
