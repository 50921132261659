import { UiService } from './../../services/ui.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public subServices = []
  constructor(private uiService: UiService) { }

  ngOnInit() {
    this.subServices = [
      'Electrical',
      'Cleaning',
      'Painting',
      'Plumbing',
      'Event Services'
    ]
  }

  public launchStore(type) {
    this.uiService.goToStore(type);
  }
}
