<section class="serbook-main">
  <app-header></app-header>
  <div class="serbook-inner">
    <div class="container">
      <app-loader></app-loader>
      <div class="serbook-header">
        <h5 class="m-0">Booking Cart</h5>
      </div>
      <div class="row">
        <div class="col-lg-9 col-md-8">
          <div class="serb-top">
            <nav>
              <div class="nav nav-pills justify-content-center" id="nav-tab" role="tablist">
                <a class="nav-item nav-link active" id="nav-services-tab" data-toggle="tab" href="#nav-services"
                  role="tab" aria-controls="nav-services" aria-selected="true">Services</a>
                <a class="nav-item nav-link" id="nav-address-tab" data-toggle="tab" href="#nav-address" role="tab"
                  aria-controls="nav-address" aria-selected="false">Address</a>
              </div>
            </nav>
          </div>
          <div class="serb-bot">
            <div class="tab-content" id="nav-tabContent">
              <div class="tab-pane fade show active" id="nav-services" role="tabpanel"
                aria-labelledby="nav-services-tab">
                <div class="empytState" *ngIf="cartData?.length === 0">
                  <div class="empty-card text-center">
                    <img src="../../../assets/img/shopping-list.svg" alt="">
                    <h5 class="m-0">
                      Services cart are empty
                    </h5>
                    <button type="button" class="btn btn-primary mt-3" [routerLink]="['/services']">Go to All
                      Services</button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-6" *ngFor="let service of cartData">
                    <div class="serb-scard">
                      <h6 class="m-0 text-capitalize">{{service.service}}</h6>
                      <span class="badge badge-primary text-capitalize">{{service.category}}</span>
                      <p class="m-0">
                        {{service.desc || 'No description...'}}
                      </p>
                      <div class="serb-scdate">
                        <h6 class="m-0 text-capitalize"><i class="fas fa-calendar"></i>
                          {{service.date | date:'mediumDate'}}, {{service.time}}</h6>
                      </div>
                      <div class="serb-scactions d-flex justify-content-end">
                        <div class="btn-group" role="group" aria-label="">
                          <button type="button" class="btn btn-light" (click)="openBookingPop(service)"><i
                              class="fas fa-edit"></i></button>
                          <button type="button" class="btn btn-light" (click)="deleteBookingCart(service.service)"><i
                              class="fas fa-trash"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="nav-address" role="tabpanel" aria-labelledby="nav-address-tab">
                <div class="main-seradd">
                  <div class="ms-card" *ngIf="userCheck === false">
                    <div class="msc-inner text-center">
                      <h6 class="m-0">You're not logged in yet</h6>
                      <p class="m-0">Please log in or sign up to address.</p>
                      <a class="btn btn-primary" [routerLink]="['/login']" role="button">Login</a>
                    </div>
                  </div>
                  <div class="ms-mcard" *ngIf="userCheck === true">
                    <div class="empytState" *ngIf="userAddressList.length === 0">
                      <div class="empty-card text-center">
                        <img src="../../../assets/img/home.svg" alt="">
                        <h5 class="m-0">
                          Your addresses are empty
                        </h5>
                        <button type="button" data-toggle="modal" data-backdrop="static" data-target="#address"
                          class="btn btn-primary mt-3">Add Address</button>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-md-6" *ngFor="let address of userAddressList; let id = index"
                        (click)="selectAddress(address, id)">
                        <!-- active -->
                        <div class="booking-card" [ngClass]="{'active': address.primary === true}">
                          <h5 class="m-0 text-capitalize">{{address.street}},</h5>
                          <h6 class="m-0 text-capitalize">{{address.city}},</h6>
                          <h6 class="m-0 text-capitalize">{{address.region}},</h6>
                          <h6 class="m-0 text-capitalize">ghana.</h6>
                          <div class="d-flex justify-content-start bphone">
                            <span class="badge badge-light"><i class="fa fa-phone" aria-hidden="true"></i>
                              {{address.phonenum}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-4">
          <div class="serbook-confirm">
            <div class="sercf-card">
              <div class="sercf-header">
                <h5 class="m-0">Booking Confirmation</h5>
              </div>
              <div class="sercf-body">
                <div class="sercf-binner">
                  <h6 class="m-0">Services</h6>
                  <ul class="list-group">
                    <li class="list-group-item" *ngFor="let service of cartData | slice:0:3; let id = index">
                      <p class="m-0 text-capitalize">{{id + 1}}. {{service.service}}</p>
                      <span class="badge badge-light text-capitalize">
                        <i class="fas fa-calendar"></i> {{service.date | date:'longDate'}}
                      </span>
                    </li>
                  </ul>
                  <div class="text-center" *ngIf="cartData?.length > 3">
                    <i class="fas fa-plus"></i> {{cartData?.length - 3}} more
                  </div>
                </div>
                <div class="sercf-binner">
                  <h6 class="m-0">Address</h6>
                  <div class="booking-card" *ngIf="selectedAddress === ''">
                    <h6 class="m-0">No address selected yet.</h6>
                  </div>
                  <div class="booking-card" *ngIf="selectedAddress !== ''">
                    <h5 class="m-0 text-capitalize">{{selectedAddress.street}},</h5>
                    <h6 class="m-0 text-capitalize">{{selectedAddress.city}},</h6>
                    <h6 class="m-0 text-capitalize">{{selectedAddress.region}},</h6>
                    <h6 class="m-0 text-capitalize">ghana</h6>
                    <div class="bphone">
                      <span class="badge badge-light mb-2">{{selectedAddress.phonenum}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="sercf-footer">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="customCheck1" (change)="checkConfirm($event)">
                  <label class="custom-control-label" for="customCheck1">
                    I understand that I am booking a service(s) subject to EjumaHub's <a
                      [routerLink]="['/terms/user']" target="_blank" rel="noopener noreferrer">Terms of Use</a>
                    and <a [routerLink]="['/terms/payment']" target="_blank" rel="noopener noreferrer">Payment
                      Policy</a>
                  </label>
                </div>
                <button type="button" class="btn btn-primary btn-block" (click)="sendBookRequest()">Confirm
                  Booking</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade mainBookModal" id="bookModal" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Book Details</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="bookmodal">
            <div class="bookinfo">
              <h5 class="m-0 text-capitalize">{{selectedService}}</h5>
            </div>
            <div class="form-group">
              <label for="bookdate">Select date for the service</label>
              <input type="date" class="form-control" [(ngModel)]="bookdate" name="bookdate" id="bookdate">
            </div>
            <div class="form-group">
              <label for="booktime">Prefer time for the serivce</label>
              <input type="time" class="form-control" [(ngModel)]="booktime" name="booktime" id="booktime">
            </div>
            <div class="form-group">
              <label for="booktime">Additional Information</label>
              <textarea name="bookdesc" id="bookdesc" class="form-control" [(ngModel)]="bookdesc" rows="5"
                placeholder="Type the description..."></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="editBookingCart()">Save</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="address" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Address</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="addressForm">
            <div class="form-group">
              <label for="street">Street Address</label>
              <input formControlName="street" type="text" placeholder="Eg: 1st Oxford Street, GA-027-9004" name="street" id="street" class="form-control">
            </div>
            <div class="form-group">
              <label for="city">City</label>
              <select formControlName="city" type="text" name="city" id="city" class="form-control">
                <option *ngFor="let city of allCities" [value]="city.city">{{city.city}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="region">Region</label>
              <select formControlName="region" type="text" name="region" id="region" class="form-control">
                <option value="Greater Accra">Greater Accra</option>
              </select>
            </div>
            <div class="form-group">
              <label for="phonenum">Phone Number</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">+233</span>
                </div>
                <input formControlName="phonenum" minlength="9" maxlength="10" type="tel" name="phonenum"  placeholder="0546003000" id="phonenum" class="form-control">
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="saveAddress()"
                [disabled]="!addressForm.valid">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</section>