import { UiService } from './../../services/ui.service';
import { AuthService } from './../../services/auth.service';
import { FuncService } from './../../services/func.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-usersettings',
  templateUrl: './usersettings.component.html',
  styleUrls: ['./usersettings.component.scss'],
})
export class UsersettingsComponent implements OnInit {
  public profileData = {
    first_name: '',
    last_name: '',
    photo_url: '',
    email: '',
    location: '',
    verified: false
  };
  public allCities = [];
  public token = '';
  constructor(
    private funcService: FuncService,
    private authService: AuthService,
    private uiService: UiService
  ) { }

  ngOnInit() {
    this.getUser();
    this.funcService.getAreas().subscribe((data: any) => {
      this.allCities = data.locations[0].states[0].cities;
    });
  }

  public getUser() {
    this.funcService.getUserProfile().then((data) => {
      this.profileData = data;
    });
  }

  public requestPassword() {
    this.authService.requestPasswordReset(this.profileData.email);
  }

  public saveEditedProfile() {
    const data = {
      first_name: this.profileData.first_name,
      last_name: this.profileData.last_name,
      location: this.profileData.location
    };
    if (
      this.profileData.first_name !== '' &&
      this.profileData.last_name !== '' &&
      this.profileData.location !== ''
    ) {
      this.funcService.editProfileData(data, this.profileData['ejuUAKey']);
    } else {
      this.uiService.showWarning('Please fill the fields & try again');
    }
  }

  public changeImg(event) {
    const file = event.target.files[0];
    const fileSize = file.size;
    if (fileSize < 200000) {
      const fileReader = new FileReader();
      fileReader.onload = (edata: any) => {
        const image = edata.target.result;
        this.profileData['photo_url'] = image;
      };
      fileReader.readAsDataURL(file);
      this.funcService.changeProfileImg(file, this.profileData['ejuUAKey']);
    } else {
      this.uiService.showWarning('File size too big, please select a file less than 2MB');
    }
  }

  public requestToken() {
    this.uiService.showLoader();
    this.funcService.sendTokenEmail(this.profileData);
    setTimeout(() => {
      this.uiService.hideLoader();
      this.uiService.showSuccess('Token sent!\n Check your mail for instructions.')
    }, 2000);
  }
  public verifyToken() {
    this.funcService.verifyTokenEmail(this.profileData, this.token)
  }
}
