import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-privacy',
  templateUrl: './terms-privacy.component.html',
  styleUrls: ['./terms-privacy.component.scss']
})
export class TermsPrivacyComponent implements OnInit {

  constructor(private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.getActiveRoute();
  }

  public getActiveRoute() {
    this.activeRoute.params.subscribe((data) => {
      if (data.id === 'user') {
        const ahrefElem = document.getElementById('terms-tab');
        ahrefElem.click()
      } else if (data.id === 'privacy') {
        const ahrefElem = document.getElementById('home-tab');
        ahrefElem.click()
      } else if (data.id === 'pro') {
        const ahrefElem = document.getElementById('pro-tab');
        ahrefElem.click()
      } else if (data.id === 'payment') {
        const ahrefElem = document.getElementById('payment-tab');
        ahrefElem.click()
      }
    })
  }

}
