<div class="sc-adcmain">
  <div class="sc-adcard">
    <img src="../../../assets/img/ad/download.png" alt="">
  </div>
  <div class="sc-adcard">
    <img src="../../../assets/img/ad/workers.png" alt="">
  </div>
  <div class="sc-adcard">
    <img src="../../../assets/img/ad/handy.png" alt="">
  </div>
  <div class="sc-adcard">
    <img src="../../../assets/img/ad/fix.png" alt="">
  </div>
</div>
