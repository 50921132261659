import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  public loadSource = new BehaviorSubject('false');
  currentState = this.loadSource.asObservable();

  public locationSource = new BehaviorSubject('false');
  locationState = this.locationSource.asObservable();

  public paySource = new BehaviorSubject(null);
  public payState = this.paySource.asObservable();

  public activeCategorySource = new BehaviorSubject(null);
  public activeCategoryState = this.activeCategorySource.asObservable();

  constructor(private toastService: ToastrService, private router: Router, private title: Title, private httpClient: HttpClient) { }

  public showError(msg) {
    this.toastService.error(`${msg}`)
  }

  public showSuccess(msg) {
    this.toastService.success(`${msg}`)
  }
  public showWarning(msg) {
    this.toastService.warning(`${msg}`)
  }

  public showLoader() {
    this.changeState('true');
  }

  public hideLoader() {
    this.changeState('false');
  }
  public showLocation() {
    this.changeState('true');
  }

  public hideLocation() {
    this.changeState('false');
  }

  public changeTitle(title) {
    this.title.setTitle(title);
  }

  public goToRoute(path) {
    return this.router.navigateByUrl(`/${path}`);
  }

  public goToBooking() {
    return this.router.navigateByUrl('/service-booking/address');
  }

  public changeState(state) {
    this.loadSource.next(state);
  }

  public changePayState(state) {
    this.paySource.next(state);
  }

  public changeCategoryState(state) {
    this.activeCategorySource.next(state);
  }

  public goToStore(type) {
    const ahref = document.createElement('a');
    ahref.target = '_blank';
    if (type === 'appstore') {
      ahref.href = 'https://apps.apple.com/gh/app/ejumahub-handyman-service/id1547877704'
    } else {
      ahref.href = 'https://play.google.com/store/apps/details?id=io.apphalabs.ejumahub'
    }
    ahref.click();
  }

  public customErrorToast() {
    this.showError('Transaction was not completed, window closed.');
    setTimeout(() => {
      this.hideLoader();
    }, 100);
  }
}
