import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';
import { UiService } from './ui.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Timestamp } from '@angular/fire/firestore';

declare var $;
declare var PaystackPop;
@Injectable({
  providedIn: 'root',
})
export class FuncService {
  public createdAt = Timestamp.now().seconds;
  private servicePath = './assets/data/services.json';
  private popularPath = './assets/data/popular.json';
  private areasPath = './assets/data/locations.json';
  private apiUrl: string = 'https://ejumahub-email.herokuapp.com/sendemail';
  public cartCount;
  private countSource = new BehaviorSubject(0);
  private imgSource = new BehaviorSubject('');
  public currentCartCount = this.countSource.asObservable();
  public changedImg = this.imgSource.asObservable();
  constructor(
    private firestore: AngularFirestore,
    private uiService: UiService,
    public httpClient: HttpClient,
    private storage: AngularFireStorage,
    private router: Router
  ) {
    this.getCartCount();
  }

  changeCartCount(data) {
    this.countSource.next(data);
  }
  changeImg(data) {
    this.imgSource.next(data);
  }
  public sendTokenEmail(data) {
    const token = Math.random().toString().substring(2, 8);
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*', 'content-type': 'application/json' })
    this.firestore.collection('ejhUsersAcc').doc(data.ejuUAKey).update({ verifyToken: token });
    this.httpClient.post(this.apiUrl, {
      email: data.email,
      subject: 'Set up your EjumaHub Account',
      first_name: data.first_name,
      type: 'verify',
      token,
      category: '',
      bookID: '',
      charge: ''
    }, {
      headers: headers
    }).toPromise().then(() => {
      console.log('Mail sent!');
    }).catch(err => {
      this.uiService.showError('An error occured.');
    })
  }

  public sendContactMessage(data) {
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*', 'content-type': 'application/json' });
    console.log(data);

    this.httpClient.post('http://localhost:8090/sendcontact', {
      email: data.email,
      messsge: data.message,
      title: data.title,
      name: data.name
    }, {
      headers: headers
    }).toPromise().then(() => {
      this.uiService.showSuccess('Message Sent!')
    }).catch(err => {
      this.uiService.showError('An error occured.');
    })
  }

  public welcomeEmail(data) {
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*', 'content-type': 'application/json' })
    this.httpClient.post(this.apiUrl, {
      email: data.email,
      subject: 'Welcome to EjumaHub',
      first_name: data.first_name,
      type: 'account',
      token: '',
      category: '',
      bookID: '',
      charge: ''
    }, {
      headers: headers
    }).toPromise().then(() => {
      console.log('Mail sent!');
    }).catch(err => {
      this.uiService.showError('An error occured.');
    })
  }

  public isEmailVerified(data) {
    if (data.verified === false || data.verified === undefined) {
      this.uiService.showSuccess('Token sent!\n Check your mail for instructions.')
      this.sendTokenEmail(data);
      setTimeout(() => {
        $('#verifyEmail').modal('show');
      }, 1500);
    } else {
      if (data.location === '') {
        this.isUserLocationSet()
      }
    }
  }

  public verifyTokenEmail(data, token) {
    this.uiService.showLoader();
    return this.firestore.collection('ejhUsersAcc').doc(data.ejuUAKey).get().toPromise().then((res) => {
      const mainData = res.data();
      if (mainData['verifyToken'] === token) {
        mainData['verified'] = true;
        delete mainData['verifyToken'];
        this.firestore.collection('ejhUsersAcc').doc(data.ejuUAKey).set(mainData);
        localStorage.removeItem('ejhUser');
        localStorage.setItem('ejhUser', JSON.stringify(mainData));
        this.uiService.hideLoader();
        this.uiService.goToRoute('/home')
        this.uiService.showSuccess('Account verified successfully');
        setTimeout(() => {
          this.welcomeEmail(data);
          if (data.location === '') {
            this.isUserLocationSet()
          }
        }, 1000);
        $('#verifyEmail').modal('hide');
      } else {
        this.uiService.hideLoader();
        this.uiService.showError('Incorrect token.\n Try again!');
      }
    });
  }

  public payWithPaystack(uemail, amount, bookingId, bookKey, index) {
    const minm = 10000;
    const maxm = 99999;
    const mainAmount = (amount).toFixed(2);
    const randNum = Math.floor(Math
      .random() * (maxm - minm + 1)) + minm;
    let subEmail = uemail.split('@').shift();
    const refId = `${bookingId}${subEmail}${randNum}`;
    var handler = PaystackPop.setup({
      key: environment.payKey,
      email: uemail,
      amount: mainAmount * 100,
      currency: 'GHS',
      ref: refId,
      callback: () => this.customPaySuccess(bookKey, index),
      onClose: () => this.uiService.customErrorToast()
    });
    handler.openIframe();
  }

  public customPaySuccess(key, index) {
    this.firestore.collection('ejhuserbookings').doc(key).get().toPromise().then((res) => {
      const bookData = res.data()['service'];
      bookData[`${index}`]['info']['paid'] = true;
      this.firestore.collection('ejhuserbookings').doc(key).update({
        service: bookData
      }).then(() => {
        this.uiService.hideLoader();
        this.uiService.showSuccess('Payment made successful.')
        this.uiService.changePayState(true);
        this.firestore.collection('ejhadminbookings', ref => ref.where('bookKey', '==', key)).get().toPromise().then((mres) => {
          mres.docs.map(a => {
            this.firestore.collection('ejhadminbookings').doc(a.data()['adBookKey']).update({
              service: bookData
            });
          });
        });
      })
    });
  }
  public async saveAddress(data) {
    this.uiService.showLoader();
    const userD = await localStorage.getItem('ejhUser');
    const userparseData = JSON.parse(userD);
    data.createdAt = this.createdAt;
    data.ejuUAKey = userparseData.ejuUAKey;

    return this.firestore
      .collection('ejhUsAddress', (ref) =>
        ref.where('ejuUAKey', '==', userparseData.ejuUAKey)
      )
      .get()
      .toPromise()
      .then((res) => {
        this.uiService.hideLoader();
        if (res.size < 6) {
          this.firestore
            .collection('ejhUsAddress')
            .add(data)
            .then((resdata) => {
              this.uiService.showSuccess('Address added successful.');
              this.firestore.collection('ejhUsAddress').doc(resdata.id).update({
                addKey: resdata.id,
              });
            });
        } else {
          this.uiService.showWarning('You can only add six(6) addresses!');
        }
      })
      .catch((err) => {
        this.uiService.hideLoader();
        this.uiService.showError(err.message);
      });
  }

  public async getUserAddressList() {
    this.uiService.showLoader();
    const userD = await localStorage.getItem('ejhUser');
    const userparseData = JSON.parse(userD);
    return this.firestore
      .collection('ejhUsAddress', (ref) =>
        ref
          .where('ejuUAKey', '==', userparseData.ejuUAKey)
          .orderBy('createdAt', 'desc')
      )
      .get()
      .toPromise();
  }

  public editUserAddress(addKey, addressData) {
    // dataKeyORDate
    this.uiService.showLoader();
    return this.firestore
      .collection('ejhUsAddress')
      .doc(addKey)
      .update(addressData)
      .then(() => {
        this.uiService.showSuccess('Address edited successful.');
        this.uiService.hideLoader();
      })
      .catch((err) => {
        this.uiService.hideLoader();
        this.uiService.showError(err.message);
      });
  }

  public async editUsAddressData(createdAt, addressData) {
    this.uiService.showLoader();
    const userD = await localStorage.getItem('ejhUser');
    const userparseData = JSON.parse(userD);
    return this.firestore
      .collection('ejhUsAddress', (ref) =>
        ref
          .where('ejuUAKey', '==', userparseData.ejuUAKey)
          .where('createdAt', '==', createdAt)
      )
      .get()
      .toPromise()
      .then((res) => {
        res.docs.map((resdata) => {
          this.editUserAddress(resdata.id, addressData);
        });
      });
  }

  public deleteUserAddress(addKey) {
    // dataKeyORDate
    this.uiService.showLoader();
    return this.firestore
      .collection('ejhUsAddress')
      .doc(addKey)
      .delete()
      .then(() => {
        this.uiService.showSuccess('Address deleted successful.');
        this.uiService.hideLoader();
      })
      .catch((err) => {
        this.uiService.hideLoader();
        this.uiService.showError(err.message);
      });
  }
  public async deleteUsAddressDate(createdAt) {
    this.uiService.showLoader();
    const userD = await localStorage.getItem('ejhUser');
    const userparseData = JSON.parse(userD);
    return this.firestore
      .collection('ejhUsAddress', (ref) =>
        ref
          .where('ejuUAKey', '==', userparseData.ejuUAKey)
          .where('createdAt', '==', createdAt)
      )
      .get()
      .toPromise()
      .then((res) => {
        res.docs.map((resdata) => {
          this.deleteUserAddress(resdata.id);
        });
      });
  }

  public getService() {
    return this.httpClient.get(this.servicePath);
  }

  public getPopularServices() {
    return this.httpClient.get(this.popularPath);
  }

  public async addToBookCart(data) {
    const bookArr = [];
    const bookcart = await localStorage.getItem('bookingCart');
    if (bookcart === null) {
      bookArr.push(data);
      const bookjsonCon = JSON.stringify(bookArr);
      localStorage.setItem('bookingCart', bookjsonCon);
      this.uiService.showSuccess('Added to booking successful.');
      this.changeCartCount(1);
    } else {
      const bookgotJson = JSON.parse(bookcart);
      const findBook = bookgotJson.findIndex((a) => a.service === data.service);
      if (findBook === -1) {
        const updateCartCount = bookgotJson.length + 1;
        bookgotJson.push(data);
        const bookwrapJson = JSON.stringify(bookgotJson);
        localStorage.setItem('bookingCart', bookwrapJson);
        this.uiService.showSuccess('Added to booking successful.');
        this.changeCartCount(updateCartCount);
      } else {
        bookgotJson[findBook] = data;
        const bookwrapJson = JSON.stringify(bookgotJson);
        localStorage.setItem('bookingCart', bookwrapJson);
        this.uiService.showSuccess('Booking edited successful.');
      }
    }
    $('#bookModal').modal('hide');
  }

  public async getCartCount() {
    const bookCart = await localStorage.getItem('bookingCart');
    const bookCartArr = JSON.parse(bookCart);
    if (bookCartArr !== null) {
      const bookCartCount = bookCartArr.length;
      this.cartCount = bookCartCount;
      this.changeCartCount(bookCartCount);
    } else {
      this.cartCount = 0;
      this.changeCartCount(0);
    }
  }

  public async getAllCartServices() {
    return await localStorage.getItem('bookingCart');
  }


  public async delBookCart(service) {
    const bookcart = await localStorage.getItem('bookingCart');
    const bookgotJson = JSON.parse(bookcart);
    const findBook = bookgotJson.findIndex((a) => a.service === service);
    const finalData = bookgotJson.splice(findBook, 1);
    const bookwrapJson = JSON.stringify(bookgotJson);
    localStorage.setItem('bookingCart', bookwrapJson);
    this.uiService.showSuccess('Booking deleted successful.');
    const newCart = await localStorage.getItem('bookingCart');
    const newgotJson = JSON.parse(newCart);
    this.cartCount = newgotJson.length;
    this.changeCartCount(newgotJson.length);
    return newgotJson;
  }

  public async editBookCart(data) {
    const bookcart = await localStorage.getItem('bookingCart');
    const bookgotJson = JSON.parse(bookcart);
    const findBook = bookgotJson.findIndex((a) => a.service === data.service);
    bookgotJson[findBook] = data;
    const bookwrapJson = JSON.stringify(bookgotJson);
    localStorage.setItem('bookingCart', bookwrapJson);
    this.uiService.showSuccess('Booking edited successful.');
    const newCart = await localStorage.getItem('bookingCart');
    const newgotJson = JSON.parse(newCart);
    $('#bookModal').modal('hide');
    return newgotJson;
  }

  // Check if user has already requested = have to get it done asap.
  public applyPro(data) {
    this.uiService.showLoader();
    const proData = {
      fname: data.fname,
      lname: data.lname,
      phonenum: data.phonenum,
      country: data.country,
      status: 'pending',
      createdAt: this.createdAt,
    };
    return this.firestore
      .collection('admin-pro-request')
      .add(proData)
      .then((res) => {
        this.firestore.collection('admin-pro-request').doc(res.id).update({
          requestKey: res.id,
        });
        this.uiService.hideLoader();
        this.uiService.showSuccess(
          'Your request has been sent successfully. You will be called shortly.'
        );
      })
      .catch((err) => {
        this.uiService.hideLoader();
        this.uiService.showError(err.message);
      });
  }

  public async getUserProfile() {
    const userD = await localStorage.getItem('ejhUser');
    const userParseData = JSON.parse(userD);
    return userParseData;
  }

  public async editProfileData(data, userKey) {
    this.uiService.showLoader();
    const userD = await localStorage.getItem('ejhUser');
    const userParseData = JSON.parse(userD);
    userParseData['first_name'] = data['first_name'];
    userParseData['last_name'] = data['last_name'];
    const newUserData = JSON.stringify(userParseData);
    return this.firestore
      .collection('ejhUsersAcc')
      .doc(userKey)
      .update(data)
      .then(() => {
        localStorage.setItem('ejhUser', newUserData);
        this.uiService.hideLoader();
        this.uiService.showSuccess('Profile updated successfully!');
      })
      .catch((err) => {
        this.uiService.hideLoader();
        this.uiService.showError(err.message);
      });
  }

  public async changeProfileImg(img, userKey) {
    this.uiService.showLoader();
    const userD = await localStorage.getItem('ejhUser');
    const userParseData = JSON.parse(userD);
    return this.storage
      .ref(`ejumausers/profile_imgs/${userKey}`)
      .put(img)
      .then((res) => {
        res.ref.getDownloadURL().then((data) => {
          userParseData['photo_url'] = data;
          const newUserData = JSON.stringify(userParseData);
          localStorage.setItem('ejhUser', newUserData);
          this.changeImg(data);
          this.firestore.collection('ejhUsersAcc').doc(userKey).update({
            photo_url: data,
          });
        });
        this.uiService.hideLoader();
        this.uiService.showSuccess('Profile Image updated successfully!');
      })
      .catch((err) => {
        this.uiService.hideLoader();
        this.uiService.showError(err.message);
      });
  }
  public async addBookingCounts(bookingsNum) {
    const bookCounts = await this.firestore.collection('bookingsCounts').doc(environment.bookCountId).get().toPromise();
    const newPending = bookCounts.data()['pending'] + bookingsNum;
    this.firestore.collection('bookingsCounts').doc(environment.bookCountId).update({
      pending: newPending,
      updatedAt: this.createdAt
    });
  }

  public async requestBook(data) {
    this.uiService.showLoader();
    const userD = await localStorage.getItem('ejhUser');
    const userParseData = JSON.parse(userD);
    data['userKey'] = userParseData.ejuUAKey;
    data['createdAt'] = this.createdAt;
    data['bookId'] = `EJH${this.createdAt}`;

    return this.firestore
      .collection('ejhuserbookings')
      .add(data)
      .then((res) => {
        this.firestore.collection('ejhuserbookings').doc(res.id).update({
          bookKey: res.id,
        });
        this.addBookingCounts(data['service'].length);
        this.uiService.hideLoader();
        this.router.navigate(['bookings']);
        this.uiService.showSuccess('Booking request sent successfully');
        data['bookKey'] = res.id;
        this.firestore
          .collection('ejhadminbookings')
          .add(data)
          .then((mres) => {
            this.firestore.collection('ejhadminbookings').doc(mres.id).update({
              adBookKey: mres.id,
            });
          });
        this.changeCartCount(0);
      })
      .catch((err) => {
        this.uiService.hideLoader();
        this.uiService.showError(err.message);
      });
  }

  public async getBookings() {
    this.uiService.showLoader();
    const userD = await localStorage.getItem('ejhUser');
    const userParseData = JSON.parse(userD);
    return this.firestore
      .collection('ejhuserbookings', (ref) =>
        ref
          .where('userKey', '==', userParseData.ejuUAKey)
          .orderBy('createdAt', 'desc')
      )
      .get()
      .toPromise();
  }

  public getWorker(wKey) {
    return this.firestore
      .collection('ejhWorkers', (ref) => ref.where('workerKey', '==', wKey))
      .get()
      .toPromise();
  }

  public getBookingSingle(key) {
    return this.firestore.collection('ejhuserbookings').doc(key).get().toPromise();
  }

  public getAreas() {
    return this.httpClient.get(this.areasPath);
  }

  public updateUserCount = async () => {
    try {
      const userCountCol = await this.firestore.collection('usersCount').doc(`${environment.userCountId}`).get().toPromise();
      await this.firestore.collection('usersCount').doc(`${environment.userCountId}`).update({
        active: userCountCol.data()['active'] + 1
      });
    } catch (error) {
      //Do nothing for now
      // console.log(error);
    }
  }

  //contact us form
  public sendMessage() {

  }

  public addWorkerRating(key, rating, workerData) {
    const newRating = workerData.totalRating + rating;
    const newpersonsRated = workerData.personsRated + 1
    this.firestore.collection('ejhWorkers').doc(key).update({
      totalRating: newRating,
      personsRated: newpersonsRated
    });
  }

  public rateReviewWorker(mrating, mreview, wKey, bookKey, uKey, worker, workIndex, booking) {
    this.uiService.showLoader();
    const reviewData = {
      admBookKey: '',
      workerKey: wKey,
      createdAt: this.createdAt,
      rating: mrating,
      review: mreview,
      bookingKey: bookKey,
      userKey: uKey
    }
    return this.firestore.collection('ejhadminbookings', ref => ref.where('bookKey', '==', bookKey)).get().toPromise().then((dres) => {
      dres.docs.map(ddata => {
        reviewData.admBookKey = ddata.data()['adBookKey'];
        this.firestore.collection('ejhReviews', ref => ref.where('admBookKey', '==', ddata.data()['adBookKey']).where('workerKey', "==", wKey)).get().toPromise().then((data) => {
          if (data.empty === true) {
            this.firestore.collection('ejhReviews').add(reviewData).then((res) => {
              this.addWorkerRating(wKey, mrating, worker);
              this.firestore.collection('ejhReviews').doc(res.id).update({
                reviewKey: res.id
              });
              let mainservice = booking.service;
              mainservice[workIndex]['info'] = {
                ...mainservice[workIndex]['info'],
                rating: mrating,
                review: mreview,
                index: workIndex,
                createdAt: this.createdAt
              }
              this.firestore.collection('ejhadminbookings').doc(ddata.data()['adBookKey']).update({ service: mainservice })
              this.firestore.collection('ejhuserbookings').doc(bookKey).update({ service: mainservice })
              this.uiService.hideLoader();
              this.uiService.showSuccess('Review submitted.');
            }).catch(err => {
              this.uiService.hideLoader();
              this.uiService.showError(`Error 1${err.message}`);
            });
          } else {
            data.docs.map((dares) => {
              this.firestore.collection('ejhReviews').doc(dares.data()['reviewKey']).update({
                review: mreview
              })
            });
            this.uiService.showSuccess('Review submitted!');
            this.uiService.hideLoader();
          }
        }).catch(err => {
          this.uiService.hideLoader();
          this.uiService.showError(`Error 2${err.message}`);
        });
      });
    }).catch(err => {
      this.uiService.hideLoader();
      this.uiService.showError(`Error 3${err.message}`);
    });
  }

  public getWorkerReview(wKey) {
    return this.firestore.collection('ejhReviews', ref => ref.where('workerKey', "==", wKey).orderBy('createdAt', 'desc').limit(10)).get().toPromise();
  }

  public async isUserLocationSet() {
    const userData = await this.getUserProfile();
    if (userData !== null && userData.location === '' || userData !== null && userData.location === undefined) {
      $('#locationModal').modal({ backdrop: 'static', keyboard: false });
    }
  }

  public async updateUserLocation(mainLoc) {
    this.uiService.showLoader();
    const userD = await localStorage.getItem('ejhUser');
    const userParseData = JSON.parse(userD);
    userParseData.location = mainLoc;
    const newUserData = JSON.stringify(userParseData);
    return this.firestore
      .collection('ejhUsersAcc')
      .doc(userParseData.ejuUAKey)
      .update({
        location: mainLoc
      })
      .then(() => {
        localStorage.setItem('ejhUser', newUserData);
        this.uiService.hideLoader();
        this.closeLocaModal();
        this.uiService.showSuccess('Location updated successfully!');
      })
      .catch((err) => {
        this.uiService.hideLoader();
        this.uiService.showError(err.message);
      });
  }

  public closeLocaModal() {
    const locModal = document.getElementById('locmodalbtn');
    locModal.setAttribute('data-dismiss', 'modal');
    locModal.click();
  }

  public addUserCount = () => {

  }
}
