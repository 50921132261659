import { ActivatedRoute } from '@angular/router';
import { UiService } from './../../services/ui.service';
import { FuncService } from './../../services/func.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
declare var $;
@Component({
  selector: 'app-service-booking',
  templateUrl: './service-booking.component.html',
  styleUrls: ['./service-booking.component.scss'],
})
export class ServiceBookingComponent implements OnInit {
  public cartData: any = [];
  public bookdate;
  public booktime;
  public addressForm: UntypedFormGroup;
  public confirmCheck = false;
  public bookdesc;
  public userAddressList = [];
  public selectedService = '';
  public selectedCategory = '';
  public userCheck = false;
  public selectedAddress: any = '';
  public allCities = [];
  public profileData;
  constructor(private funcService: FuncService, private uiService: UiService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.funcService.getUserProfile().then((data) => {
      this.profileData = data;
    });
    this.addressForm = new UntypedFormGroup({
      street: new UntypedFormControl('', Validators.required),
      city: new UntypedFormControl('', Validators.required),
      region: new UntypedFormControl('Greater Accra'),
      phonenum: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"),
        Validators.minLength(9),
        Validators.maxLength(10)
      ])),
      country: new UntypedFormControl('Ghana, 00233', Validators.required),
    });
    this.getAllServices();
    this.getUserAddress();

    this.funcService.getAreas().subscribe((data: any) => {
      this.allCities = data.locations[0].states[0].cities;
    });
    // this.getPath();
  }

  public getPath() {
    this.activatedRoute.params.subscribe(data => {
      console.log(data.id)
      if (data.id) {
        const navItem = document.getElementById('nav-address-tab');
        navItem.click();
      }
    });
  }

  public getAllServices() {
    this.funcService.getAllCartServices().then((data) => {
      console.log(data);

      const allCart = JSON.parse(data);
      this.cartData = allCart;
      if (this.cartData === null) {
        this.cartData = [];
        const navItem = document.getElementById('nav-services-tab');
        navItem.click();
      } else {
        const navItem = document.getElementById('nav-address-tab');
        navItem.click();
      }
    });
  }

  public deleteBookingCart(service) {
    this.funcService.delBookCart(service).then((data) => {
      this.cartData = data;
    });
  }

  public openBookingPop(service) {
    $('#bookModal').modal({ backdrop: 'static', keyboard: false });
    this.selectedService = service['service'];
    this.selectedCategory = service['category'];
    this.bookdate = service['date'];
    this.booktime = service['time'];
    this.bookdesc = service['desc'];
  }

  public editBookingCart() {
    const bookData = {
      service: this.selectedService,
      date: this.bookdate,
      desc: this.bookdesc,
      time: this.booktime,
      category: this.selectedCategory,
    };
    this.funcService.editBookCart(bookData).then((data) => {
      this.cartData = data;
    });
  }

  public closeModal() {
    this.selectedService = '';
    this.bookdate = '';
    this.bookdesc = '';
    this.booktime = '';
  }

  public async getUserAddress() {
    this.userAddressList = [];
    const userAddress = await localStorage.getItem('userLogged');
    if (userAddress === null) {
      this.userCheck = false;
    } else {
      this.userCheck = true;
      this.funcService.getUserAddressList().then((data) => {
        if (!data.empty) {
          data.docs.map(res => {
            this.userAddressList.push(res.data());
          });
          this.userAddressList[0]['primary'] = true;
          this.selectedAddress = this.userAddressList[0];
        }
        this.uiService.hideLoader();
      });
    }
  }

  public selectAddress(address, id) {
    this.selectedAddress = address;
    this.userAddressList.map(data => {
      data['primary'] = false;
    });
    this.userAddressList[id]['primary'] = true;
  }

  public sendBookRequest() {
    const addObj = {
      worker: '',
      rating: 0,
      review: '',
      createdAt: 0,
      status: 'pending',
      fee: '',
      paid: false
    };
    this.cartData.map(data => {
      data['info'] = addObj;
    });
    const bookData = {
      service: this.cartData,
      address: this.selectedAddress,
    };
    if (this.profileData.verified === true) {
      if (this.cartData.length !== 0 && this.selectedAddress !== '' && this.confirmCheck === true) {
        this.funcService.requestBook(bookData);
        localStorage.removeItem('bookingCart');
        this.selectedAddress = '';
      } else if (this.selectedAddress === '') {
        this.uiService.showWarning('Please add an address to your booking.');
      } else if (this.confirmCheck === false) {
        this.uiService.showWarning('Please agree to the terms and condition before confirming booking.');
      } else if (this.cartData.length === 0) {
        this.uiService.showWarning('Services list is empty.');
      }
    } else {
      this.uiService.showWarning('Please verify email! Go to Account settings.')
    }
  }

  public checkConfirm(event) {
    this.confirmCheck = event.target.checked;
  }

  public saveAddress() {
    this.funcService.saveAddress(this.addressForm.value);
    setTimeout(() => {
      this.addressForm.reset();
      this.getUserAddress();
    }, 3000);
  }
}
