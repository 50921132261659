<section class="loginmain">
  <app-header></app-header>
  <div class="loginbot">
    <div class="container">
      <app-loader></app-loader>
      <div class="loginb-main">
        <div class="lbm-header text-center">
          <h5 class="m-0">Get Started...</h5>
          <p class="m-0">Create an account to manage your bookings.</p>
        </div>
        <form [formGroup]="registerForm">
          <div class="lbm-form">
            <div class="form-group">
              <input formControlName="first_name" type="text" class="form-control" name="first_name" id="first_name"
                placeholder="First Name">
            </div>
            <div class="form-group">
              <input formControlName="last_name" type="text" class="form-control" name="last_name" id="last_name"
                placeholder="Last Name">
            </div>
            <div class="form-group">
              <input formControlName="email" type="email" class="form-control" name="email" id="email"
                placeholder="Email">
            </div>
            <div class="form-group">
              <input formControlName="password" type="password" class="form-control" name="password" id="password"
                placeholder="Password">
            </div>
            <div class="form-group">
              <button type="button" [disabled]="!registerForm.valid" (click)="registerUser()"
                class="btn btn-primary btn-block">Sign Up</button>
            </div>
          </div>
          <div class="lbm-options text-center">
            <button type="button" class="btn btn-light btn-block" (click)="goToLogin()">
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</section>
