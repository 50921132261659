import { UsersettingsComponent } from './main/usersettings/usersettings.component';
import { AddressListComponent } from './main/address-list/address-list.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './default/login/login.component';
import { SignupComponent } from './default/signup/signup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ForgotpassComponent } from './default/forgotpass/forgotpass.component';
import { HomeComponent } from './default/home/home.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ContactComponent } from './default/contact/contact.component';
import { AboutusComponent } from './default/aboutus/aboutus.component';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire//compat/storage';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

import { StarRatingModule } from 'angular-star-rating';
import { environment } from 'src/environments/environment.prod';
import { UiService } from './services/ui.service';
import { FooterComponent } from './sub-components/footer/footer.component';
import { HeaderComponent } from './sub-components/header/header.component';
import { LoaderComponent } from './sub-components/loader/loader.component';
import { NotifyComponent } from './sub-components/notify/notify.component';
import { FuncService } from './services/func.service';
import { AuthGuard } from './services/auth.guard';
import { ServiceListComponent } from './main/service-list/service-list.component';
import { UserprofileComponent } from './main/userprofile/userprofile.component';
import { EditprofileComponent } from './main/editprofile/editprofile.component';
import { BookingsComponent } from './main/bookings/bookings.component';
import { ProProfileComponent } from './main/pro-profile/pro-profile.component';
import { TermsPrivacyComponent } from './main/terms-privacy/terms-privacy.component';
import { ApplyComponent } from './main/apply/apply.component';
import { ServiceBookingComponent } from './main/service-booking/service-booking.component';
import { ServiceCategoryComponent } from './main/service-category/service-category.component';
import { ToastService } from './services/toast.service';
import { ToastrModule } from 'ngx-toastr';
import { SidePromoComponent } from './sub-components/side-promo/side-promo.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { HttpClientModule } from '@angular/common/http';
import { LoggedInGuard } from './services/logged-in.guard';
import { BnNgIdleService } from 'bn-ng-idle';

import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore, enableIndexedDbPersistence } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    ForgotpassComponent,
    HomeComponent,
    ContactComponent,
    AboutusComponent,
    FooterComponent,
    HeaderComponent,
    LoaderComponent,
    NotifyComponent,
    ServiceListComponent,
    UserprofileComponent,
    EditprofileComponent,
    BookingsComponent,
    ProProfileComponent,
    AddressListComponent,
    UsersettingsComponent,
    TermsPrivacyComponent,
    ApplyComponent,
    ServiceBookingComponent,
    ServiceCategoryComponent,
    SidePromoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxSkeletonLoaderModule,
    Ng2SearchPipeModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    StarRatingModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot(),
    FormsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage())
  ],
  providers: [
    UiService,
    FuncService,
    AuthGuard,
    LoggedInGuard,
    BnNgIdleService,
    ToastService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
