import { UiService } from './../../services/ui.service';
import { FuncService } from './../../services/func.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import * as lodash from 'lodash';
@Component({
  selector: 'app-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss']
})
export class AddressListComponent implements OnInit {
  public addressForm: UntypedFormGroup;
  public mainEditAddress;
  public allCities = [];
  public adType;
  public street;
  public city;
  public phonenum;
  public region;
  public country;
  public addressList = [];
  public emptyState = null;
  public skeletonList = [1, 2, 3, 4, 5, 6];
  constructor(private funcService: FuncService, private uiService: UiService) { }

  ngOnInit() {
    this.getAddressList();
    this.addressForm = new UntypedFormGroup({
      street: new UntypedFormControl('', Validators.required),
      city: new UntypedFormControl('', Validators.required),
      region: new UntypedFormControl('Greater Accra'),
      phonenum: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"),
        Validators.minLength(9),
        Validators.maxLength(10)
      ])),
      country: new UntypedFormControl('Ghana, 00233', Validators.required),
    });
    this.funcService.getAreas().subscribe((data: any) => {
      this.allCities = data.locations[0].states[0].cities;
    });
  }

  public saveAddress() {
    this.funcService.saveAddress(this.addressForm.value);
    setTimeout(() => {
      this.addressForm.reset();
      this.getAddressList();
    }, 3000);
  }

  public editAddress() {
    const editData = {
      street: this.street,
      city: this.city,
      region: this.region,
      phonenum: this.phonenum,
      country: this.country,
    };
    if (this.mainEditAddress['addKey'] === undefined) {
      if (this.street !== '' && this.city !== '' && this.phonenum !== '') {
        this.funcService.editUsAddressData(this.mainEditAddress['createdAt'], editData);
        setTimeout(() => {
          this.street = '';
          this.city = '';
          this.region = 'Greater Accra';
          this.phonenum = '';
          this.country = 'Ghana, 00233';
          this.getAddressList();
        }, 3000);
      } else {
        this.uiService.showWarning('Please fill all the fields.');
      }
    } else {
      if (this.street !== '' && this.city !== '' && this.phonenum !== '') {
        this.funcService.editUserAddress(this.mainEditAddress['addKey'], editData);
        setTimeout(() => {
          this.addressForm.reset();
          this.getAddressList();
        }, 3000);
      } else {
        this.uiService.showWarning('Please fill all the fields.');
      }
    }
  }

  public changeType(type, data?) {
    this.adType = type;
    if (type === 'edit') {
      this.mainEditAddress = data;
      this.street = data['street'];
      this.city = data['city'];
      this.region = data['region'];
      this.phonenum = data['phonenum'];
      this.country = data['country'];
    }
  }

  public getAddressList() {
    const addressArr = [];
    this.funcService.getUserAddressList().then((data) => {
      if (data.empty) {
        this.uiService.hideLoader();
        this.emptyState = true;
      } else {
        data.docs.map(resdata => {
          this.uiService.hideLoader();
          addressArr.push(resdata.data());
          const uniqData = lodash.uniqBy(addressArr, 'addKey');
          this.addressList = uniqData;
          this.emptyState = false;
        });
      }
    });
  }

  public deleteAddress(addressData, id) {
    this.addressList.splice(id, 1);
    if (addressData['addKey'] === undefined) {
      this.funcService.deleteUsAddressDate(addressData['createdAt']);
      setTimeout(() => {
        this.getAddressList();
      }, 2000);
    } else {
      this.funcService.deleteUserAddress(addressData['addKey']);
      setTimeout(() => {
        this.getAddressList();
      }, 2000);
    }
  }
}
