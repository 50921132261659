import { Component, OnInit } from '@angular/core';
declare var $;
@Component({
  selector: 'app-service-category',
  templateUrl: './service-category.component.html',
  styleUrls: ['./service-category.component.scss']
})
export class ServiceCategoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  public switchTabs(tab) {
    let activeTab = `#categoryTab a[href=${tab}]`
    $(`${activeTab}`).on('click', function (e) {
      e.preventDefault()
      $(this).tab('show')
    })
  }
}
