<div class="container-fluid p-0">
  <router-outlet></router-outlet>
  <div class="modal fade" id="locationModal" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Your Location</h5>
          <button type="button" class="close" id="locmodalbtn">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="city">City</label>
            <select [(ngModel)]="city" type="text" name="city" id="city" class="form-control">
              <option *ngFor="let city of allCities" [value]="city.city">{{city.city}}</option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" [disabled]="disableBtn" (click)="saveLocation()">Save</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="verifyEmail" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Email verification</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="openLocation()">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="city">Enter the token</label>
            <input type="text" name="token" id="token" class="form-control" [(ngModel)]="token">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" [disabled]="token === ''"
            (click)="verifyToken()">Verify</button>
        </div>
      </div>
    </div>
  </div>
</div>