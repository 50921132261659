<section class="contact-main">
  <app-header></app-header>
  <div class="jumb-bg">
    <div class="jumb-bginner">
      <div class="container">
        <h4 class="m-0">
          Contact EjumaHub
        </h4>
      </div>
    </div>
  </div>
  <div class="contact-data">
    <div class="container">
      <div class="row">
        <div class="col-lg-3">
          <div class="contact-left">
            <div class="contact-m-top">
              <h5>
                Let's Talk
              </h5>
              <p>
                You can always give us a quick call and talk to our friendly customer care staff. They will be happy
                to answer all your questions.
              </p>
            </div>
            <div class="contact-left-bot">
              <div class="d-flex justify-content-start">
                <div class="clb-icon">
                  <i class="fas fa-phone"></i>
                </div>
                <div class="clb-text">
                  <h6 class="m-0">
                    Phone
                  </h6>
                  <p class="m-0">
                    0302984047 / +23320808599
                  </p>
                </div>
              </div>
              <div class="d-flex justify-content-start">
                <div class="clb-icon">
                  <i class="fas fa-map-marked-alt"></i>
                </div>
                <div class="clb-text">
                  <h6 class="m-0">
                    Address
                  </h6>
                  <p class="m-0">
                    V324 George Bush Hwy <br>
                    McCarthy Hill Junction - Accra
                  </p>
                </div>
              </div>
              <div class="d-flex justify-content-start">
                <div class="clb-icon">
                  <i class="fas fa-globe-africa"></i>
                </div>
                <div class="clb-text">
                  <h6 class="m-0">
                    Country
                  </h6>
                  <p class="m-0">
                    Ghana
                  </p>
                </div>
              </div>
              <div class="d-flex justify-content-start">
                <div class="clb-icon">
                  <i class="fas fa-at"></i>
                </div>
                <div class="clb-text">
                  <h6 class="m-0">
                    Email
                  </h6>
                  <p class="m-0">
                    <a href="#">hello@ejumahub.com</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="contact-right">
            <div class="contact-m-top">
              <h5 class="m-0">
                Send Us A Message
              </h5>
            </div>
            <div class="contact-bot-form">
              <form [formGroup]="contactForm">
                <div class="row mb-2">
                  <div class="col">
                    <input formControlName="name" name="name" type="text" class="form-control" placeholder="Your name">
                  </div>
                  <div class="col">
                    <input formControlName="email" name="email" type="email" class="form-control"
                      placeholder="Your Email">
                  </div>
                </div>
                <div class="form-group mb-2">
                  <input formControlName="title" type="text" class="form-control" name="title" placeholder="Title">
                </div>
                <div class="form-group mb-2">
                  <textarea class="form-control" name="message" formControlName="message" rows="6"
                    placeholder="Type your message..."></textarea>
                </div>
                <div class="text-left">
                  <button type="button" [disabled]="!contactForm.valid" (click)="sendMessage()"
                    class="btn btn-primary">Send Message</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3970.970980877062!2d-0.20647028483363136!3d5.571308495960714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdf9b1ba002f40b%3A0x1235b8e7cb927969!2sAppha%20Labs!5e0!3m2!1sen!2sgh!4v1594746458897!5m2!1sen!2sgh"
        width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
    </div>
  </div>
  <app-footer></app-footer>
</section>